import Swiper from 'swiper';
import { Scrollbar } from 'swiper/modules';
/*
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

gsap.registerPlugin(ScrollTrigger);
*/

class NewsCarousel {
    constructor() {
        this.newsCarousels = document.querySelectorAll('[data-news-carousel="true"]');
    }

    init() {
        if (this.newsCarousels.length === 0) return;

        this.newsCarousels.forEach((newsCarousel) => this.initializeSwiper(newsCarousel));
    }

    initializeSwiper(nodeElement) {
        this.swiper = new Swiper(nodeElement, {
            slidesPerView: 'auto',
            spaceBetween: 32,
            speed: 800,
            scrollbar: {
                el: '.swiper-scrollbar',
                hide: false,
                draggable: true,
            },
            modules: [Scrollbar],
        });

        /*
        console.log('isTouchDevice', this.isTouchDevice());

        // Vérifier si l'appareil est tactile
        if (!this.isTouchDevice()) {
            this.initializeScrollTrigger(nodeElement.closest('.news-carousel'), swiper);
        }
        */
    }

    /*
    initializeScrollTrigger(nodeElement, swiper) {
        ScrollTrigger.create({
            trigger: nodeElement,
            start: 'top top',
            end: () => `+=${nodeElement.scrollWidth}`,
            pin: true,
            pinSpacing: true,
            scrub: true,
            onUpdate: (self) => {
                const progress = self.progress;
                const maxTranslate = swiper.maxTranslate();
                swiper.setTranslate(progress * maxTranslate); // Inverser le sens de défilement
            },
        });
    }

    isTouchDevice() {
        return 'ontouchstart' in window || navigator.maxTouchPoints > 0 || window.matchMedia('(pointer: coarse)').matches;
    }
    */
}

export default new NewsCarousel();