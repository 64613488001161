class Accordion {
    constructor(triggerSelector) {
        this.triggerSelector = triggerSelector;
    }

    init() {
        document.querySelectorAll(this.triggerSelector).forEach((trigger) => {
            this.checkAriaExpanded(trigger);
            trigger.addEventListener('click', (e) => this.handleEvent(e));
        });

        document.addEventListener('keydown', (e) => {
            if (e.key === 'Escape') {
                this.closeAllContents();
            }
        });

        // Ajouter un gestionnaire d'événements pour les clics en dehors de l'accordéon
        document.addEventListener('click', (event) => {
            if (!event.target.closest(this.triggerSelector)) {
                this.closeAllContents();
            }
        });
    }

    handleEvent(e) {
        const targetSelector = e.target.getAttribute('data-target');
        const target = document.querySelector(targetSelector);
        if (target) {
            const isVisible = target.getAttribute('data-visible') === 'true';
            if (isVisible) {
                target.setAttribute('data-visible', 'false');
                $(target).slideUp(300);
            } else {
                this.closeAllContents();
                target.setAttribute('data-visible', 'true');
                $(target).slideDown(300);
            }
            this.checkAriaExpanded(e.target);
        }
    }

    checkAriaExpanded(trigger) {
        const targetSelector = trigger.getAttribute('data-target');
        const target = document.querySelector(targetSelector);
        if (target) {
            const isVisible = target.getAttribute('data-visible') === 'true';
            trigger.setAttribute('aria-expanded', isVisible);
        }
    }

    closeAllContents() {
        const contents = $('[data-visible="true"]');
        contents.each(function () {
            $(this).slideUp(300, () => {
                $(this).attr('data-visible', 'false');
            });
        });
    }
}

export default new Accordion('[data-trigger="accordion"]');