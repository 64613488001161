import { adminbar, header, time } from '../utils/variables';

export default {
    init() {
        this.smoothScroll();
        this.detectHoverAndPointer();
    },

    scrollDirection() {
        let lastScrollTop = 0;

        $(window).on('scroll', () => {

            let st = $(window).scrollTop();

            // downscroll code
            if (st > lastScrollTop) $('body').removeClass('scrolling-up').addClass('scrolling-down')
            // upscroll code
            else $('body').removeClass('scrolling-down').addClass('scrolling-up');

            lastScrollTop = st;
        });
    },

    /* Smooth scroll */
    smoothScroll(trigger) {

        const self = this;

        trigger = trigger || '[data-smooth-scroll]';

        $(document).on('click', trigger, (e) => {
            self.setSmoothScroll(e);
        });
    },

    setSmoothScroll(e) {

        e.preventDefault();

        let href = $(e.currentTarget).attr('href');

        if ($(href).length === 0) return false;

        const offset = {
            gap: 40, // laisser de l'espace vide
            header: Math.round($(header.wrapper).height()),
            target: $(href).offset().top,
            adminbar: adminbar.height,
        };

        $('html,body').stop().animate({
            scrollTop: offset.target - offset.header - offset.adminbar - offset.gap,
        }, time.lg, 'easeInOutQuart');

        return false;
    },

    detectHoverAndPointer() {
        const mediaQuery = window.matchMedia('(hover: hover) and (pointer: fine)');
        document.documentElement.dataset.hoverSupported = mediaQuery.matches;

        if (mediaQuery.matches) {
            console.log('L\'appareil supporte le hover et a un pointeur fin.');
        } else {
            console.log('L\'appareil ne supporte pas le hover ou n\'a pas un pointeur fin.');
        }
    },
}
