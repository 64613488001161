import gsap from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import { breakpoint } from './variables';

gsap.registerPlugin(ScrollTrigger);

export class ScrollPin {
    constructor({ pinElement, pinReference, start, end, markers }) {
        this.pinElement = typeof pinElement === 'string' ? document.querySelector(pinElement) : pinElement;
        this.pinReference = typeof pinReference === 'string' ? document.querySelector(pinReference) : pinReference;
        this.start = start || 'top top';
        this.end = end || 'bottom bottom';
        this.markers = markers || false;

        if (!this.pinElement && !this.pinReference) return;

        this.init();
        window.addEventListener('resizeEnd', this.updatePin.bind(this));
    }

    init() {
        if (this.shouldPin()) {
            this.createPin();
        }
    }

    shouldPin() {
        return window.innerWidth >= breakpoint.sidebar;
    }

    createPin() {
        gsap.matchMedia().add(`(min-width: ${breakpoint.sidebar}px)`, () => {
            this.ST = ScrollTrigger.create({
                trigger: this.pinReference,
                pin: this.pinElement,
                start: this.start,
                end: this.end,
                markers: this.markers,
                invalidateOnRefresh: true,
            });
        });
    }

    updatePin() {
        if (this.shouldPin() && this.ST) {
            this.ST.refresh();
        }
    }

    kill() {
        if (this.shouldPin() && this.ST) {
            this.ST.kill();
        }
    }
}
