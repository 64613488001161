/* Gravity Form scripts */
import gsap from 'gsap';

class Gform {
    constructor() {
        this.noticeValidationError = document.querySelector('.gform_validation_errors');
        this.gfieldValidationMessages = document.querySelectorAll('.gfield_validation_message');
    }

    init() {
        this.setFieldErrorMessagesAttributes();
        document.addEventListener('click', (e) => {
            this.closeValidationErrorNotice(e);
            this.showFieldErrorMessage(e);
        });
    }

    closeValidationErrorNotice(e) {
        const validationErrorElement = e.target.closest('.gform_validation_errors') || e.target === this.noticeValidationError;

        if (validationErrorElement) {
            gsap.to(this.noticeValidationError, {
                duration: 0.5,
                opacity: 0,
                y: '-100%',
                ease: 'back.in(1.7)',
                onComplete: () => {
                    this.noticeValidationError.style.display = 'none';
                    this.scrollToForm(validationErrorElement);
                },
            });
        }
    }

    // Gravity forms : ajout d'une class spécifique pour chaque type de champ
    add_default_classes() {

        if ($('.gform_wrapper').length === 0) {
            return false;
        }

        // Text
        $('.gform_wrapper .ginput_container_text').each(function (index, el) {
            $(el).closest('.gfield').addClass('type-text');
        });
        // Email
        $('.gform_wrapper .ginput_container_email').each(function (index, el) {
            $(el).closest('.gfield').addClass('type-email');
        });
        // Phone
        $('.gform_wrapper .ginput_container_phone').each(function (index, el) {
            $(el).closest('.gfield').addClass('type-phone');
        });
        // Number
        $('.gform_wrapper .ginput_container_number').each(function (index, el) {
            $(el).closest('.gfield').addClass('type-number');
        });
        // Password
        $('.gform_wrapper .ginput_container_password').each(function (index, el) {
            $(el).closest('.gfield').addClass('type-password');
        });
        // Date
        $('.gform_wrapper .ginput_container_date').each(function (index, el) {
            $(el).closest('.gfield').addClass('type-date');
        });
        // Radio
        $('.gform_wrapper .ginput_container_radio').each(function (index, el) {
            $(el).closest('.gfield').addClass('type-radio');
        });
        // Checkbox
        $('.gform_wrapper .ginput_container_checkbox').each(function (index, el) {
            $(el).closest('.gfield').addClass('type-checkbox');
        });
        // Select
        $('.gform_wrapper .gfield:not(.address) .gfield_select').each(function (index, el) {
            $(el).closest('.gfield').addClass('type-select');
        });
        // List
        $('.gform_wrapper .ginput_container_list').each(function (index, el) {
            $(el).closest('.gfield').addClass('type-list');
        });
        // File
        $('.gform_wrapper .ginput_container_fileupload').each(function (index, el) {
            $(el).closest('.gfield').addClass('type-file');
        });
        // Textarea
        $('.gform_wrapper .ginput_container_textarea').each(function (index, el) {
            $(el).closest('.gfield').addClass('type-textarea');
        });
        // Recaptcha
        $('.gform_wrapper .ginput_recaptcha').each(function (index, el) {
            $(el).closest('.gfield').addClass('type-recaptcha');
        });
        // Complex field
        $('.gform_wrapper .ginput_complex, .gform_wrapper .clear-multi').each(function (index, el) {
            $(el).closest('.gfield').addClass('type-complex');
        });
        // Complex field : name
        $('.gform_wrapper .ginput_container_name').each(function (index, el) {
            $(el).closest('.gfield').addClass('type-name');
        });
        // Complex field : adress
        $('.gform_wrapper .ginput_container_address').each(function (index, el) {
            $(el).closest('.gfield').addClass('type-address');
        });
        // Complex field  : hour / date
        $('.gform_wrapper .clear-multi').each(function (index, el) {
            $(el).closest('.gfield').addClass('type-time-date');
        });
    }

    setFieldErrorMessagesAttributes() {
        if (this.gfieldValidationMessages.length === 0) return;

        this.gfieldValidationMessages.forEach((message) => {
            message.setAttribute('tabindex', '0');
            message.setAttribute('role', 'button');
        });
    }

    showFieldErrorMessage(e) {
        if (e.target.classList.contains('gfield_validation_message')) {
            alert(e.target.textContent);
        }
    }

    scrollToForm(element) {
        const anchor = element.closest('.gform_wrapper').querySelector('.gform_anchor');

        if (anchor) {
            const header = document.querySelector('.header');
            const headerHeight = header ? header.offsetHeight : 0;
            const elementPosition = anchor.getBoundingClientRect().top + window.scrollY;
            const offsetPosition = elementPosition - headerHeight;

            window.scrollTo({
                top: offsetPosition,
                behavior: 'smooth',
            });
        }
    }
}

export default new Gform();