import gsap from 'gsap';
import Draggable from 'gsap/Draggable';
import ScrollTrigger from 'gsap/ScrollTrigger';
import headerAccessibility from '../partials/header-accessibility';

gsap.registerPlugin(Draggable, ScrollTrigger);

export default {
    init() {
        console.log('headerAccessibility.animationsAreEnabled():', headerAccessibility.animationsAreEnabled());
        const rowsEl = [
            document.getElementById('row0'),
            document.getElementById('row1'),
        ].filter(Boolean);

        const scrollbarThumb = document.querySelector('.scrollbar-thumb');
        const logosRows = document.querySelector('.logo-rows');

        const updateCarouselPosition = (progress) => {
            if (rowsEl.length > 1) {
                tl1.progress(progress);
                tl2.progress(progress);
            } else {
                tl1.progress(progress);
            }
        };

        // Initialiser les animations GSAP
        const tl1 = gsap.timeline({ repeat: -1, paused: false });
        let tl2;

        if (rowsEl.length > 1) {
            tl2 = gsap.timeline({ repeat: -1, paused: false });
            tl1.to(rowsEl[0], { x: '-100%', duration: 30, ease: 'none' });
            tl2.from(rowsEl[1], { x: '-100%', duration: 30, ease: 'none' });
        } else {
            tl1.to(rowsEl[0], { x: '-100%', duration: 30, ease: 'none' });
        }

        // Fonction pour vérifier si l'élément est visible dans le viewport
        const isElementInViewport = (el) => {
            const rect = el.getBoundingClientRect();
            return (
                rect.top >= 0 &&
                rect.left >= 0 &&
                rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
                rect.right <= (window.innerWidth || document.documentElement.clientWidth)
            );
        };

        // Fonction pour mettre en pause de manière fluide
        const smoothPause = (enable) => {
            enable = enable || true;
            if (!enable) return;

            if (rowsEl.length > 1) {
                gsap.to([tl1, tl2], { timeScale: 0, duration: 1 });
            } else {
                gsap.to(tl1, { timeScale: 0, duration: 1 });
            }
            console.log('pause');
        };

        // Fonction pour redémarrer de manière fluide
        const smoothPlay = (enable) => {
            enable = enable || true;
            if (!enable) return;

            if (rowsEl.length > 1) {
                gsap.to([tl1, tl2], { timeScale: 1, duration: 1 });
            } else {
                gsap.to(tl1, { timeScale: 1, duration: 1 });
            }
            console.log('play');
        };

        // Mettre en pause la timeline au survol
        if (headerAccessibility.animationsAreEnabled()) {
            rowsEl.forEach(row => {
                row.addEventListener('mouseenter', smoothPause);
                row.addEventListener('mouseleave', smoothPlay);
            });
        }

        // Initialiser Draggable pour la scrollbar
        Draggable.create(scrollbarThumb, {
            type: 'x',
            bounds: '.scrollbar',
            onDrag: function () {
                const progress = this.x / this.maxX;
                updateCarouselPosition(progress);
                if (!headerAccessibility.animationsAreEnabled()) {
                    smoothPlay();
                }
            },
            onDragEnd: function () {
                if (!headerAccessibility.animationsAreEnabled()) {
                    smoothPause();
                }
            },
        });

        // Gestion des événements pour le drag du carousel
        let isDragging = false;
        let startX;
        let startProgress;

        const startDrag = (e) => {
            isDragging = true;
            startX = e.clientX || e.touches[0].clientX;
            startProgress = tl1.progress();
            if (!headerAccessibility.animationsAreEnabled()) {
                smoothPlay();
            }
        };

        const drag = (e) => {
            if (!isDragging) return;
            const x = e.clientX || e.touches[0].clientX;
            const delta = (startX - x) / window.innerWidth;
            let newProgress = startProgress + delta;
            newProgress = newProgress - Math.floor(newProgress); // Assure une boucle infinie
            updateCarouselPosition(newProgress);
            gsap.set(scrollbarThumb, { x: newProgress * (scrollbarThumb.parentNode.offsetWidth - scrollbarThumb.offsetWidth) });
        };

        const endDrag = () => {
            isDragging = false;
            if (!headerAccessibility.animationsAreEnabled()) {
                smoothPause();
            }
        };

        rowsEl.forEach(row => {
            row.addEventListener('mousedown', startDrag);
            row.addEventListener('touchstart', startDrag);
        });

        document.addEventListener('mousemove', drag);
        document.addEventListener('touchmove', drag);
        document.addEventListener('mouseup', endDrag);
        document.addEventListener('touchend', endDrag);

        // Stocker l'état précédent de la visibilité
        if (logosRows) {
            var previousVisibility = isElementInViewport(logosRows);
        }

        // Écouter l'événement personnalisé "scrollEnd"
        window.addEventListener('scrollEnd', () => {
            if (!headerAccessibility.animationsAreEnabled()) return;

            if (logosRows) {
                const currentVisibility = isElementInViewport(logosRows);
                if (currentVisibility !== previousVisibility) {
                    if (currentVisibility) {
                        smoothPlay();
                    } else {
                        smoothPause();
                    }
                    previousVisibility = currentVisibility;
                }
            }
        });

        // Vérifier l'état des animations lors de l'initialisation
        if (headerAccessibility.animationsAreEnabled()) {
            // Démarrer les animations
            tl1.play();

            if (rowsEl.length > 1) {
                tl2.play();
            }
        }
        else {
            smoothPause();
        }
    },
};