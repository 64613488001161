import Poles from '../home/poles';
import homeLoader from '../layouts/home-loader';

export default {
    init() {
        Poles.init();
        homeLoader.init();
        console.log('home.js', 'init');
    },
    finalize() {
        // JavaScript to be fired on the home page, after the init JS
        console.log('home.js', 'finalize');
    },
    load() {
        console.log('home.js', 'load');
    },
    complete() {
        // 
    },
};