import gsap from 'gsap/all';
// import scrollAnimations from '../utils/scroll-animations';

class HeaderAccessibility {
    constructor() {
        this.accessibilityHeader = document.querySelector('.header-accessibility');
        this.accessibilityTrigger = document.querySelector('[data-js="accessibility-trigger"]');
        this.accessibilityPanel = document.querySelector('[data-js="accessibility-panel"]');
        this.isExpanded = false;

        // Ajouter des variables pour les triggers
        this.fontTrigger = document.querySelector('.js_btn-accessibility-font');
        this.animationsTrigger = document.querySelector('.js_btn-accessibility-animations');
        this.lineHeightTrigger = document.querySelector('.js_btn-accessibility-line-height');
    }

    init() {
        if (!this.accessibilityTrigger || !this.accessibilityPanel) return;

        this.accessibilityPanel.style.display = 'none';

        this.accessibilityTrigger.addEventListener('click', this.togglePanel.bind(this));
        document.addEventListener('keydown', this.handleKeydown.bind(this));
        document.addEventListener('click', this.handleClickOutside.bind(this));

        this.initAccessibilityFeatures();
    }

    reset() {
        this.isExpanded = false;
        this.closePanel();
        this.updateAriaExpanded();
        this.updateAccessibilityHeader();
    }

    handleAnimationsToggle() {
        // if (document.body.classList.contains('accessibility_animations')) {
            // document.dispatchEvent(new Event('animationsEnabled'));
        // } else {
            // document.dispatchEvent(new Event('animationsDisabled'));
        // }

        window.location.reload();

        // scrollAnimations.init(); // Réinitialiser les animations
    }

    togglePanel() {
        this.isExpanded = !this.isExpanded;
        this.updateAriaExpanded();
        this.updateAccessibilityHeader();
        this.isExpanded ? this.showPanel() : this.closePanel();
    }

    showPanel() {
        this.accessibilityPanel.style.display = 'block';
        gsap.fromTo(this.accessibilityPanel, {
            opacity: 0,
            y: -50,
        }, {
            opacity: 1,
            y: 0,
            duration: 0.3,
            ease: 'back.out(1.4)',
        });
    }

    closePanel() {
        gsap.to(this.accessibilityPanel, {
            opacity: 0,
            y: -50,
            duration: 0.3,
            ease: 'back.in(1.4)',
            onComplete: () => {
                this.accessibilityPanel.style.display = 'none';
            },
        });
    }

    handleKeydown(e) {
        if (e.key === 'Escape') {
            this.reset();
        }
    }

    handleClickOutside(e) {
        if (!e.target.closest('.header-accessibility')) {
            this.reset();
        }
    }

    updateAriaExpanded() {
        this.accessibilityTrigger.setAttribute('aria-expanded', this.isExpanded);
    }

    updateAccessibilityHeader() {
        if (this.accessibilityHeader) {
            this.accessibilityHeader.setAttribute('data-accessibility-panel-open', this.isExpanded);
        }
    }

    initAccessibilityFeatures() {
        this.checkInitialCookieValues();
        this.initIndividualClicks();
    }

    checkInitialCookieValues() {
        this.checkCookieValue(this.fontTrigger, 'accessibility_font');
        this.checkCookieValue(this.animationsTrigger, 'accessibility_animations', 'true');
        this.checkCookieValue(this.lineHeightTrigger, 'accessibility_line_height');
    }

    initIndividualClicks() {
        this.initAccessibilityFeature(this.fontTrigger, 'accessibility_font');
        this.initAccessibilityFeature(this.animationsTrigger, 'accessibility_animations');
        this.initAccessibilityFeature(this.lineHeightTrigger, 'accessibility_line_height');
        this.initAccessibilityColors();
    }

    initAccessibilityFeature(button, cookieName) {
        if (!button) return;

        button.addEventListener('click', () => {
            this.handleFeatureButtonClick(button, cookieName);

            if(cookieName === 'accessibility_animations') {
                this.handleAnimationsToggle();
            }
        });
    }

    handleFeatureButtonClick(button, cookieName) {
        let cookieState = String(!button.classList.contains('active'));

        this.addAccessibilityClass(button, cookieName);

        $.cookie(cookieName, cookieState, {
            path: '/',
        });

        console.log('click:', cookieName, cookieState);
    }

    initAccessibilityColors() {
        const buttons = document.querySelectorAll('.js_btn-accessibility-colors');
        buttons.forEach(button => {
            const cookieName = 'accessibility_colors';
            const cookieClass = 'accessibility-colors';

            let cookieState = button.getAttribute('data-colors');
            let classToAdd = `${cookieClass}-${cookieState}`;

            this.checkCookieValue(button, cookieName, cookieState, classToAdd);

            button.addEventListener('click', (event) => {
                event.preventDefault();
                event.stopPropagation();
                this.handleColorButtonClick(event);
            });
        });
    }

    handleColorButtonClick(event) {
        const button = event.currentTarget;
        const cookieName = 'accessibility_colors';
        const cookieClass = 'accessibility-colors';

        let cookieState = button.getAttribute('data-colors');
        let classToAdd = `${cookieClass}-${cookieState}`;

        document.querySelectorAll('.js_btn-accessibility-colors').forEach(btn => {
            btn.classList.remove('active');
        });

        this.addAccessibilityClass(button, classToAdd);

        document.querySelectorAll('.js_btn-accessibility-colors').forEach(btn => {
            if (!btn.classList.contains('active')) {
                let cookieData = btn.getAttribute('data-colors');
                let classToDelete = `${cookieClass}-${cookieData}`;

                document.body.classList.remove(classToDelete);
            }
        });

        $.cookie(cookieName, cookieState, {
            path: '/',
        });
    }

    addAccessibilityClass(elem, classAdd) {
        if (!elem.classList.contains('active')) {
            elem.classList.add('active');
            document.body.classList.add(classAdd);
        } else {
            elem.classList.remove('active');
            document.body.classList.remove(classAdd);
        }
    }

    checkCookieValue(elem, cookieName, defaultState = 'false', classAdd = cookieName) {
        let cookieState = $.cookie(cookieName);
        if (cookieState === undefined) {
            cookieState = defaultState;
        }

        if (cookieState === 'true') {
            document.body.classList.add(classAdd);
            elem.classList.add('active');
        } else {
            document.body.classList.remove(classAdd);
            elem.classList.remove('active');
        }
    }

    animationsAreEnabled() {
        return document.body.classList.contains('accessibility_animations');
    }
}

export default new HeaderAccessibility();