import { gsap } from 'gsap';
import { getCookie, setCookie } from '../utils/functions';

class HomeLoader {
    constructor() {
        this.app = document.getElementById('app');
        this.loader = document.getElementById('home-loader');
        this.animationWebp = document.getElementById('home-loader-animation');
        this.duration = {
            webp: 2160, // 2,16s
            clipPath: 1000, // 1s
        }
    }

    init() {
        if (!this.loader && !this.animationWebp) return;

        // Vérifier si le cookie accessibility_animations est false
        if (getCookie('accessibility_animations') === 'false') {
            // Déclencher directement l'événement custom sans animations
            const event = new CustomEvent('loaderDisappeared');
            document.dispatchEvent(event);
            return;
        }

        // Vérifier si le cookie existe déjà
        if (!getCookie('loaderDisappeared')) {
            // document.body.style.overflow = 'hidden';
            window.addEventListener('load', this.startAnimationSequence.bind(this));
        } else {
            // Si le cookie existe, déclencher directement l'événement custom
            const event = new CustomEvent('loaderDisappeared');
            document.dispatchEvent(event);
        }
    }

    animateClipPath() {
        gsap.to(this.app,
            {
                css: {
                    clipPath: 'circle(100% at 50% 50%)',
                    webkitClipPath: 'circle(100% at 50% 50%)',
                    willChange: '-webkit-clip-path, clip-path',
                },
                duration: this.duration.clipPath / 1000,
                ease: 'power2.inOut',
                onComplete: () => {
                    document.body.removeAttribute('style');
                    this.loader.style.display = 'none';
                    // this.app.style.clipPath = 'none';
                    // this.app.style.webkitClipPath = 'none';
                    this.app.style.height = 'auto';
                },
            });
    }

    startAnimationSequence() {
        this.animationWebp.style.display = 'block';
        this.animationWebp.src = this.animationWebp.dataset.src;

        this.animationWebp.onload = () => {
            this.playWebpAnimation(() => {
                this.vanishLoader();
            });
        };

        this.animationWebp.onerror = () => {
            this.vanishLoader();
        };
    }

    playWebpAnimation(callback) {
        setTimeout(() => {
            // this.loader.classList.add('webp-played');

            console.log('Animation .webp terminée');

            if (callback) callback();
        }, this.duration.webp);
    }

    vanishLoader() {
        this.app.style.clipPath = 'circle(100% at 50% 50%)';

        // Dispatch custom event
        setTimeout(() => {
            const event = new CustomEvent('loaderDisappeared');
            document.dispatchEvent(event);
        }, this.duration.clipPath / 2);

        // Animate clip-path
        setTimeout(() => {
            this.loader.style.display = 'none';
            this.app.style.height = 'auto';
            this.app.style.clipPath = 'none';

            // Définir le cookie pour indiquer que le loader a disparu
            setCookie('loaderDisappeared', 'true'); // Le cookie expira à la fin de la session

            console.log('Animation terminée');
        }, this.duration.clipPath);
    }
}

export default {
    init() {
        const homeLoader = new HomeLoader();
        homeLoader.init();
    },
}
