import { formatFileSize } from '../utils/functions';
class FormDefault {
    constructor() {
        this.inputs = document.querySelectorAll('input');
        this.textareas = document.querySelectorAll('textarea');

        // Liez les méthodes au contexte de la classe
        this.handleInputClick = this.handleInputClick.bind(this);
        this.handleInputBlur = this.handleInputBlur.bind(this);
        this.handleInputFocus = this.handleInputFocus.bind(this);
        this.handleFileInputChange = this.handleFileInputChange.bind(this);
        this.handleTextareaInput = this.handleTextareaInput.bind(this);
    }

    init() {
        // Vérifiez les inputs au chargement de la page
        this.checkInputsOnLoad();
        this.checkInputsIsFilledOnChange();
        this.initTextareas();

        // Ajoutez l'écouteur d'événements pour les clics
        document.addEventListener('click', this.handleInputClick);

        // Ajoutez les écouteurs d'événements pour les inputs de type file
        document.querySelectorAll('input[type="file"]').forEach(input => {
            input.addEventListener('change', this.handleFileInputChange);
        });
    }

    initTextareas() {
        if (!this.textareas.length) return;

        this.textareas.forEach(textarea => {
            textarea.rows = 2;
            textarea.addEventListener('input', this.handleTextareaInput);
        });
    }

    checkInputsOnLoad() {
        // Sélectionnez tous les inputs dans les éléments avec la classe 'field-floating'
        const inputs = document.querySelectorAll('.field-floating input');

        if (!inputs.length) return;

        inputs.forEach(input => {
            const parent = input.closest('.field-floating');
            const label = parent.querySelector('label');

            // Si un label est trouvé et qu'il n'a pas la classe 'gfield'
            if (label && !parent.classList.contains('gfield')) {
                // Ajoutez la classe 'floating' si l'input est pré-rempli
                if (input.value !== '') {
                    label.classList.add('floating');
                }

                // Ajoutez les écouteurs d'événements pour focus et blur
                input.addEventListener('focus', this.handleInputFocus);
                input.addEventListener('blur', this.handleInputBlur);
            }
        });
    }

    checkInputsIsFilledOnChange() {
        if (!this.inputs.length) return;

        this.inputs.forEach(input => {
            input.addEventListener('input', function () {
                if (input.value.trim() !== '') {
                    input.classList.add('filled');
                } else {
                    input.classList.remove('filled');
                }
            });
        });
    }

    handleTextareaInput(event) {
        const textarea = event.target;
        textarea.rows = 3; // Reset to minimum rows
        const rows = Math.min(20, Math.floor(textarea.scrollHeight / 24)); // Adjust rows based on content
        textarea.rows = rows;
    }

    handleInputClick(event) {
        // Vérifiez si l'élément cliqué est un input dans un parent avec la classe 'field-floating'
        if (event.target.tagName.toLowerCase() === 'input' && event.target.closest('.field-floating')) {
            const input = event.target;
            const parent = input.closest('.field-floating');
            const label = parent.querySelector('label');

            // Si un label n'est pas trouvé ou s'il a la classe 'gfield', on arrête
            if (!label || parent.classList.contains('gfield')) return;

            // Ajoutez la classe 'floating' au label
            label.classList.add('floating');

            // Ajoutez les écouteurs d'événements pour focus et blur
            input.addEventListener('focus', this.handleInputFocus);
            input.addEventListener('blur', this.handleInputBlur);

            // Vérifiez initialement si l'input est pré-rempli
            if (input.value !== '') {
                label.classList.add('floating');
            }
        }
    }

    handleInputFocus(event) {
        const input = event.target;
        const label = input.closest('.field-floating').querySelector('label');

        // Ajoutez la classe 'floating' au focus de l'input
        if (label) {
            label.classList.add('floating');
        }
    }

    handleInputBlur(event) {
        const input = event.target;
        const label = input.closest('.field-floating').querySelector('label');

        // Retirez la classe 'floating' si l'input est vide
        if (input.value === '') {
            label.classList.remove('floating');
        }
    }

    handleFileInputChange(event) {
        const input = event.target;
        const file = input.files[0];
        const container = input.parentElement;

        // Remove existing file info if any
        const existingFileInfo = container.querySelector('.file-info');
        if (existingFileInfo) {
            existingFileInfo.remove();
        }

        if (file) {
            console.log('file', file);

            // Create file info container
            const fileInfo = document.createElement('div');
            fileInfo.classList.add('file-info');
            fileInfo.style.display = 'flex';
            fileInfo.style.alignItems = 'center';
            fileInfo.style.gap = '1em';

            // Create span for file name
            const fileName = document.createElement('span');
            fileName.textContent = `${file.name} - ${formatFileSize(file.size)}`;

            // Create delete button
            const deleteButton = document.createElement('button');
            deleteButton.type = 'button';
            deleteButton.classList.add('delete-file-button');
            deleteButton.addEventListener('click', function () {
                input.value = ''; // Reset the input
                fileInfo.remove(); // Remove the file info
            });

            // Append file name and delete button to file info container
            fileInfo.appendChild(fileName);
            fileInfo.appendChild(deleteButton);

            // Append file info container to the input container
            container.appendChild(fileInfo);
        }
    }
}

export default new FormDefault();