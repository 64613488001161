export function get_max_value(array) {

    let max = array[0];

    for (let i = 1; i < array.length; ++i) {
        if (array[i] >= max) {
            max = array[i];
        }
    }

    return max;
}

export function isOverlapping(element1, element2) {
    const rect1 = element1.getBoundingClientRect();
    const rect2 = element2.getBoundingClientRect();

    return !(rect1.right < rect2.left ||
        rect1.left > rect2.right ||
        rect1.bottom < rect2.top ||
        rect1.top > rect2.bottom);
}

export function flexSlideDown(element, duration = 500) {
    element.style.overflow = 'hidden';
    element.style.height = '0';
    element.style.display = 'flex';
    const height = element.scrollHeight;
    element.style.transition = `height ${duration}ms ease-in-out`;
    requestAnimationFrame(() => {
        element.style.height = `${height}px`;
    });
    setTimeout(() => {
        element.style.height = '';
        element.style.transition = '';
        element.style.overflow = 'visible';
    }, duration);
}

// Fonction pour animer le slideUp avec overflow
export function flexSlideUp(element, duration = 500) {
    const height = element.scrollHeight;
    element.style.overflow = 'hidden';
    element.style.height = `${height}px`;

    requestAnimationFrame(() => {
        element.style.transition = `height ${duration}ms ease-in-out`;
        element.style.height = '0';
    });

    setTimeout(() => {
        element.style.display = 'none';
        element.style.height = '';
        element.style.transition = '';
    }, duration);
}

export function setThousandSeparator(number, thousandSep) {
    // par défaut le séparateur des milliers sera un espace insécable
    thousandSep = thousandSep || ' ';

    var parts = number.toString().split(/[,|.]/);

    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, thousandSep);

    return parts;
}

// Fonction pour définir un cookie
export function setCookie(name, value, days) {
    var expires = '';
    if (days) {
        var date = new Date();
        date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
        expires = '; expires=' + date.toUTCString();
    }
    document.cookie = name + '=' + (value || '') + expires + '; path=/';
}

// Fonction pour obtenir un cookie
export function getCookie(name) {
    var nameEQ = name + '=';
    var ca = document.cookie.split(';');
    for (var i = 0; i < ca.length; i++) {
        var c = ca[i];
        while (c.charAt(0) == ' ') c = c.substring(1, c.length);
        if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length, c.length);
    }
    return null;
}

// Fonction pour vérifier si un élément est visible dans le viewport
export function isInViewport(element) {
    const rect = element.getBoundingClientRect();
    return (
        rect.top >= 0 &&
        rect.left >= 0 &&
        rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
        rect.right <= (window.innerWidth || document.documentElement.clientWidth)
    );
}
