import gsap from 'gsap/all';
import Swiper from 'swiper';
import { Navigation } from 'swiper/modules';
import { breakpoint } from '../utils/variables';

class MegaMenu {
    constructor() {
        // Elements
        this.megaMenuWrapper = document.querySelector('.header-mega-menu');
        this.megaMenu = document.getElementById('mega-menu');
        this.megaMenuSlides = this.megaMenu.querySelectorAll('.swiper-slide');
        this.megaMenuLeft = this.megaMenu.querySelector('.mega-menu-left');
        this.megaMenuRight = this.megaMenu.querySelector('.mega-menu-right');
        this.megaMenuItemsLinks = this.megaMenu.querySelectorAll('a'); // items à animer lors de l'ouverture du mega menu
        this.megaMenuItems = this.megaMenu.querySelectorAll('a, h3'); // items à animer lors de l'ouverture du mega menu
        this.megaMenuPoles = this.megaMenu.querySelector('.mega-menu-poles');
        this.megaMenuMobileBottomContent = this.megaMenuRight.parentElement;
        this.megaMenuMobileInfos = this.megaMenu.querySelector('.mega-menu-mobile-infos');
        this.megaMenuDevices = this.megaMenu.querySelector('.mega-menu-devices');
        this.megaMenuPolesElements = gsap.utils.toArray([this.megaMenuDevices.children, this.megaMenuPoles.children]);
        this.burgerTrigger = document.querySelector('[data-open-mega-menu="true"]');
        this.toggleSubmenuSelector = '[data-js="toggle-submenu"]';
        this.toggleSubmenus = document.querySelectorAll(this.toggleSubmenuSelector);
        this.toggleExpandElement = document.querySelector('[data-js="toggle-poles-expand"]');
        this.menuMobileItems = gsap.utils.toArray([this.megaMenu.querySelector('.menu-grid').children, this.megaMenu.querySelector('.mega-menu-resources').children]);

        // Variables
        this.megaMenuIsOpen = false;
        this.megaMenuSliderHasBeenInitialized = false;
        this.swiperSpeed = 600;
        this.megaMenuHasBeenOpened = false;
        this.breakpoint = breakpoint.mobile; // 500px
        this.previousWidth = window.innerWidth;

        window.addEventListener('resizeEnd', this.handleResizeEnd.bind(this));
    }

    init() {
        if (!this.megaMenu) return;

        this.initAccordion();
        this.initPolesBottomContent();
        this.setMenuDataStyle();
        this.setInitialMegaMenuState();

        document.addEventListener('keypress', (event) => {
            if (event.key === 'Escape' && this.megaMenuIsOpen) {
                this.closeMegaMenu();
                this.megaMenuIsOpen = false;
                this.refreshBurgerButtonText();
            }
        });

        document.addEventListener('click', (e) => {
            if (e.target === this.burgerTrigger) {
                // Si le mega menu est fermé
                if (!this.megaMenuIsOpen) {
                    if (window.innerWidth < this.breakpoint)
                        this.openMobileMenu();
                    else
                        this.openMegaMenu();

                }

                // Si le mega menu est ouvert
                else {
                    if (window.innerWidth < this.breakpoint)
                        this.closeMobileMenu();
                    else
                        this.closeMegaMenu();

                    this.megaMenuIsOpen = false;
                }

                // Change le texte du burger
                this.refreshBurgerButtonText();
            }
        });
    }

    initSlider() {
        if (!this.megaMenu || this.megaMenuSliderHasBeenInitialized) {
            // console.log('Mega menu slider already initialized or megaMenu not found');
            return;
        }

        // console.log('Initializing Mega menu slider...');
        this.megaMenu.swiper = new Swiper(this.megaMenu, {
            slidesPerView: 'auto',
            speed: this.swiperSpeed,
            navigation: {
                nextEl: '.button-slide-next',
                prevEl: '.button-slide-prev',
            },
            modules: [Navigation],
        });

        this.megaMenuSliderHasBeenInitialized = true;
        this.handleSlideChange();
        // console.log('Mega menu slider initialized');
    }

    destroySlider() {
        if (this.megaMenu.swiper) {
            this.megaMenu.swiper.destroy(true, true);
            this.megaMenuSliderHasBeenInitialized = false;
            // console.log('Mega menu slider destroyed');
        }
    }

    initAccordion() {
        this.toggleSubmenus.forEach(button => {
            const target = document.querySelector(button.dataset.toggleTarget) || button.nextElementSibling;

            if (button.ariaExpanded === 'true') {
                target.style.display = 'flex';
            }
            else {
                target.style.display = 'none';
                button.ariaExpanded = 'false';
            }

            button.addEventListener('click', this.toggleAccordion.bind(this));
        });

        // console.log('Accordion initialized');
    }

    destroyAccordion() {
        this.megaMenuWrapper.querySelectorAll(this.toggleSubmenuSelector).forEach(button => {
            button.removeEventListener('click', this.toggleAccordion);
        });
        // console.log('Accordion destroyed');
    }

    toggleAccordion(event) {
        const button = event.currentTarget;
        const target = document.querySelector(button.dataset.toggleTarget) || button.nextElementSibling;

        if (!target) return;

        // console.log('Toggling accordion...');

        if (button.ariaExpanded === 'true') {
            target.style.display = 'none';
            button.ariaExpanded = 'false';
            button.parentElement.classList.remove('active');
        }

        else {
            target.style.display = 'flex';
            button.ariaExpanded = 'true';
            button.parentElement.classList.add('active');
        }
    }

    handleAccordionResize() {
        this.toggleSubmenus.forEach(button => {
            const target = document.querySelector(button.dataset.toggleTarget) || button.nextElementSibling;

            if (window.innerWidth < this.breakpoint) {
                if (this.previousWidth >= this.breakpoint) {
                    target.style.display = 'none';
                    button.ariaExpanded = 'false';
                    button.parentElement.classList.remove('active');
                }
            } else {
                target.style.display = 'flex';
                button.ariaExpanded = 'false';
                button.parentElement.classList.remove('active');
            }
        });
    }

    initPolesBottomContent() {
        if (!this.toggleExpandElement) return;

        gsap.set(this.megaMenuPolesElements, { opacity: 0 });
        this.toggleExpandElement.addEventListener('click', this.expandPolesBottomContent.bind(this));
    }

    expandPolesBottomContent(event) {
        const button = event.currentTarget || this.toggleExpandElement;

        this.animatePolesBottomContent(button.ariaExpanded);

        button.ariaExpanded = button.ariaExpanded === 'true' ? 'false' : 'true';
    }

    animatePolesBottomContent(ariaExpanded) {
        ariaExpanded = ariaExpanded || 'false';

        // console.log('Animating poles bottom content...');

        if (ariaExpanded === 'true') {
            // console.log('Content is already expanded, so we hide it');

            this.megaMenuMobileBottomContent.classList.remove('active');

            gsap.to(this.megaMenuPolesElements,
                {
                    opacity: 0, y: 50, duration: 0.5, stagger: -0.1, ease: 'power1.in', onComplete: () => {
                        // Réinitialiser le scroll interne à zéro
                        const simplebarContentWrapper = this.megaMenuRight.querySelector('.simplebar-content-wrapper');
                        if (simplebarContentWrapper) {
                            simplebarContentWrapper.scrollTop = 0;
                            // console.log('Scroll reset');
                        }

                        this.megaMenuRight.classList.remove('active');
                        this.megaMenuMobileInfos.style.display = 'grid';

                        gsap.to(this.megaMenuMobileInfos.children,
                            { opacity: 1, x: 0, duration: 0.5, stagger: -0.05, ease: 'power1.out' },
                        );
                    },
                }
            );
        }
        else {
            // console.log('Content is not expanded, so we expand it');
            this.megaMenuRight.classList.add('active');

            gsap.to(this.megaMenuMobileInfos.children,
                {
                    opacity: 0, x: 50, duration: 0.5, stagger: 0.1, ease: 'power1.in', onComplete: () => {
                        this.megaMenuMobileBottomContent.classList.add('active');
                        this.megaMenuMobileInfos.style.display = 'none';

                        gsap.fromTo(this.megaMenuPolesElements,
                            { opacity: 0, y: 50 },
                            { opacity: 1, y: 0, duration: 0.5, stagger: 0.05, ease: 'power1.out' }
                        );
                    },
                },
            );
        }
    }

    openMegaMenu() {
        this.updateHtmlDataAttributeState('opening');
        this.animateShowItems(this.megaMenuItems);

        this.animateOpenMegaMenu(() => {
            this.updateHtmlDataAttributeState('opened');
            this.animateShowItems(this.megaMenuSlides, () => {
                // console.log('Mega menu opened');
                this.initSlider();
                this.refreshSlider();

                setTimeout(() => {
                    this.showSwiperNavigation();
                    this.verifySlidesVisibility();
                    this.checkLinkVisibility();
                    this.megaMenuLeft.classList.add('loaded');
                }, 150);
            });
        });

        this.megaMenuIsOpen = true;
    }

    closeMegaMenu() {
        this.animateHideItems(this.megaMenuSlides);
        this.animateHideItems(this.megaMenuItems, () => {
            this.megaMenuLeft.classList.remove('loaded');
            this.updateHtmlDataAttributeState('closing');
            this.closeMegaMenuAnimation(() => {
                this.updateHtmlDataAttributeState('closed');
            });
        });
    }

    openMobileMenu() {
        this.updateHtmlDataAttributeState('opening');

        this.animateOpenMegaMenu(() => {

            this.megaMenuLeft.style.display = 'flex';
            gsap.fromTo(this.megaMenuMobileBottomContent,
                { y: '100%' },
                { y: 0, duration: 0.8, ease: 'back.out(1.4)' },
            );

            gsap.fromTo(this.menuMobileItems,
                { opacity: 0, y: 50 },
                {
                    opacity: 1, y: 0, duration: 0.5, delay: 0.2, stagger: 0.1, ease: 'power1.out', onComplete: () => {
                        this.updateHtmlDataAttributeState('opened');
                        this.megaMenuLeft.classList.add('loaded');
                    },
                },
            );
        });

        this.megaMenuIsOpen = true;
    }

    closeMobileMenu() {
        gsap.fromTo(this.menuMobileItems,
            { opacity: 1, y: 0 },
            {
                opacity: 0, y: 50, duration: 0.5, stagger: -0.1, ease: 'power1.out', onComplete: () => {
                    this.closeMegaMenuAnimation(() => {
                        this.megaMenuLeft.style.display = 'none';

                        this.updateHtmlDataAttributeState('closed');
                    });
                },
            },
        );

        gsap.fromTo(this.megaMenuMobileBottomContent,
            { opacity: 1, y: 0 },
            { opacity: 1, y: '100%', duration: 0.5, ease: 'back.in(1.4)' }
        );

    }

    animateOpenMegaMenu(onCompleteCallback) {
        this.megaMenuWrapper.style.display = 'flex';

        gsap.fromTo(this.megaMenuWrapper, {
            opacity: 0,
        }, {
            opacity: 1,
            duration: 0.3,
            onComplete: onCompleteCallback,
        });
    }

    closeMegaMenuAnimation(onCompleteCallback) {
        gsap.to(this.megaMenuWrapper, {
            duration: 0.3,
            opacity: 0,
            onComplete: () => {
                onCompleteCallback();
                this.megaMenuWrapper.style.display = 'none';
            },
        });
    }

    animateHideItems(items, onCompleteCallback) {
        if (!items || !items.length) return;

        gsap.to(items, {
            duration: 0.3,
            opacity: 0,
            x: 50,
            stagger: -0.00175,
            ease: 'power1.in',
            onComplete: onCompleteCallback,
        });
    }

    animateShowItems(items, onCompleteCallback) {
        if (!items || !items.length) return;

        // console.log('Showing items...');

        gsap.fromTo(items, {
            opacity: 0,
            x: 50,
        }, {
            duration: 0.3,
            opacity: 1,
            x: 0,
            stagger: 0.0175,
            ease: 'power1.out',
            onComplete: onCompleteCallback,
        });
    }

    handleResizeEnd() {
        // console.log('Resize end');

        this.setMenuDataStyle();
        this.setInitialMegaMenuState();

        if (window.innerWidth < this.breakpoint) {
            this.destroySlider();
        }

        else {
            this.initSlider();
            this.refreshSlider();
            this.showSwiperNavigation();
            this.verifySlidesVisibility();
            this.verifyActiveSlideVisibility();
            this.checkLinkVisibility();
        }

        this.handleAccordionResize();
        this.megaMenuWrapper.removeAttribute('data-resize-from');
        this.previousWidth = window.innerWidth;
    }

    handleSlideChange() {
        if (!this.megaMenu.swiper) return;

        this.megaMenu.swiper.on('slideChange', () => {
            // console.log('Slide changed');
            this.verifySlidesVisibility();
            this.verifyActiveSlideVisibility();
            this.megaMenu.swiper.update();
        });
    }

    refreshBurgerButtonText() {
        this.burgerTrigger.textContent =
            this.megaMenuIsOpen
                ? this.burgerTrigger.getAttribute('data-close-text-content')
                : this.burgerTrigger.getAttribute('data-open-text-content');
    }

    refreshSlider() {
        if (!this.megaMenu.swiper) return;

        // console.log('Updating slider...');
        this.megaMenu.swiper.updateProgress();
        this.megaMenu.swiper.updateSize();
        this.megaMenu.swiper.update();
    }

    verifySlidesVisibility() {
        const slides = this.megaMenu.querySelectorAll('.swiper-slide');

        if (!slides.length) return;

        let allVisible = true;

        slides.forEach(slide => {
            const rect = slide.getBoundingClientRect();
            if (rect.right > window.innerWidth || rect.left < 0) {
                allVisible = false;
            }
        });

        this.megaMenu.setAttribute('data-slides-visibles', allVisible ? 'true' : 'false');
    }

    verifyActiveSlideVisibility() {
        const slides = this.megaMenu.querySelectorAll('.swiper-slide');

        if (!slides.length) return;

        let activeSlide = null;

        slides.forEach(slide => {
            const rect = slide.getBoundingClientRect();
            if (rect.right <= window.innerWidth && rect.left >= 0) {
                activeSlide = slide;
            }
        });

        if (activeSlide) {
            slides.forEach(slide => slide.classList.remove('swiper-slide-active'));
            activeSlide.classList.add('swiper-slide-active');
        }
    }

    updateHtmlDataAttributeState(value) {
        document.documentElement.setAttribute('data-mega-menu-state', value);
    }

    showSwiperNavigation() {
        if (!this.megaMenu.swiper && this.megaMenuSliderHasBeenInitialized) return;
        setTimeout(() => {
            this.megaMenu.swiper.navigation.prevEl.style.display = 'grid';
            this.megaMenu.swiper.navigation.nextEl.style.display = 'grid';
        }, 300);
    }

    checkLinkVisibility() {
        const firstLinkNavigation = this.megaMenuLeft.querySelector('.mega-menu-navigation a:first-child');
        const lastLinkResources = this.megaMenuLeft.querySelector('.mega-menu-resources a:last-child');
        const simplebarTracks = this.megaMenuLeft.querySelectorAll('.simplebar-track');

        if (!lastLinkResources || !firstLinkNavigation) return;

        const observerOptions = {
            root: this.megaMenu.querySelector('.mega-menu-left'),
            threshold: 1.0,
        };

        const observerCallback = (entries) => {
            let lastLinkVisible = false;
            let firstLinkVisible = false;

            entries.forEach(entry => {
                if (entry.target === lastLinkResources) {
                    lastLinkVisible = entry.isIntersecting;
                }
                if (entry.target === firstLinkNavigation) {
                    firstLinkVisible = entry.isIntersecting;
                }
            });

            simplebarTracks.forEach(track => {
                if (lastLinkVisible && firstLinkVisible) {
                    track.classList.add('hidden');
                } else {
                    track.classList.remove('hidden');
                }
            });
        };

        const observer = new IntersectionObserver(observerCallback, observerOptions);

        observer.observe(lastLinkResources);
        observer.observe(firstLinkNavigation);
    }

    setInitialMegaMenuState() {
        if(this.megaMenuIsOpen) {
            this.megaMenuWrapper.dataset.resizeFrom = this.previousWidth >= this.breakpoint ? 'desktop' : 'mobile';
        }

        if (window.innerWidth < this.breakpoint) {

            if (!this.megaMenuIsOpen) {
                // gsap.set(this.megaMenuSlides, { opacity: 1, x: 0 });
                gsap.set(this.megaMenuItems, { opacity: 1, x: 0 });
                gsap.set(this.megaMenuMobileBottomContent, { opacity: 1, y: '100%' });
                gsap.set(this.menuMobileItems, { opacity: 0, y: 50 });
            }

            else {
                if (this.previousWidth >= this.breakpoint) {
                    if (this.toggleExpandElement.ariaExpanded !== 'true') {
                        // console.log('Animating poles bottom content...');
                        this.animatePolesBottomContent('true');
                    }
                }
            }
            
            if (this.previousWidth >= this.breakpoint) {
                gsap.set(this.megaMenuSlides, { opacity: 1, x: 0, y: 0 });
            }

            // console.log('this.previousWidth', this.previousWidth);
        }

        else {
            this.menuMobileItems.forEach(item => item.style.display = 'flex');
            this.megaMenuItems.forEach(item => item.removeAttribute('style'));
            this.megaMenuPolesElements.forEach(item => item.removeAttribute('style'));

            if (this.previousWidth < this.breakpoint) {
                gsap.set(this.menuMobileItems, { opacity: 1, y: 0 });
                gsap.set(this.megaMenuPolesElements, { opacity: 1, y: 0 });
            }

            if (!this.megaMenuIsOpen) {
                gsap.set(this.megaMenuLeft, { display: 'flex' });
                gsap.set(this.megaMenuRight, { display: 'flex' });
                gsap.set(this.megaMenuPolesElements, { opacity: 1, y: 0 });
                gsap.set(this.megaMenuSlides, { opacity: 0, x: 50 });
            }

            // console.log('Setting initial state for desktop');
        }
    }

    setMenuDataStyle() {
        if (window.innerWidth < this.breakpoint)
            this.megaMenuWrapper.dataset.menuStyle = 'mobile';
        else
            this.megaMenuWrapper.dataset.menuStyle = 'desktop';
    }
}

export default new MegaMenu();