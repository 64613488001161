import gsap from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import headerAccessibility from '../partials/header-accessibility';

gsap.registerPlugin(ScrollTrigger);

const selectAll = (e) => document.querySelectorAll(e);

// Parallax
const panels = selectAll('.js-parallax');
var offsetY = 100; // Dans variables.scss, changez la valeur de $offset-y si vous modifier cette valeur

// Events
window.addEventListener('load', function () {
    parallax();
});

// Functions
function parallax() {
    if (!headerAccessibility.animationsAreEnabled()) return;

    panels.forEach((panel) => {
        var imageWrappers = panel.querySelectorAll('.js-parallax-img');
        // console.log(imageWrappers);

        gsap.fromTo(
            imageWrappers,
            {
                y: -offsetY,
            },
            {
                y: offsetY,
                scrollTrigger: {
                    trigger: panel,
                    scrub: true,
                    start: 'top bottom', // position of trigger meets the scroller position
                },
                ease: 'none',
            }
        );
    });
}
