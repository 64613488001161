import { ScrollPin } from '../utils/scroll-pin';
import { breakpoint } from '../utils/variables';

class TextImage {
    constructor(section) {
        this.section = section;
        this.pinElement = this.section.querySelector('[data-js-pin-element]');
        this.pinReference = this.section.querySelector('[data-js-pin-reference]');
        
        if (!this.pinElement || !this.pinReference) {
            console.warn('Pin element or reference not found');
            return;
        }

        this.init();
        this.previousShouldPin = this.shouldPin(); // Initialiser l'état précédent
        window.addEventListener('resizeEnd', this.updatePin.bind(this));
    }

    init() {
        this.pinElementsOnScroll();
    }

    pinElementsOnScroll() {
        if (!this.pinReference || !this.pinElement) return;

        if (!this.shouldPin()) return;

        this.SP = new ScrollPin({
            pinElement: this.pinElement,
            pinReference: this.pinReference,
            start: () => `top ${this.getStartOffset()}px`,
            end: () => `bottom ${this.getStartOffset() + this.pinElement.offsetHeight}px`,
            // markers: true,
        });

        console.log('pinElementsOnScroll', this.SP);
    }

    getStartOffset() {
        const header = document.querySelector('.header');
        const adminbar = document.querySelector('#wpadminbar');

        let offset = 8;

        offset += header ? header.offsetHeight : 0;
        offset += adminbar ? adminbar.offsetHeight : 0;

        return offset;
    }

    shouldPin() {
        if (window.innerWidth < breakpoint.sidebar) return;

        return this.pinElement.offsetHeight + 100 <= this.pinReference.offsetHeight;
    }

    updatePin() {
        const currentShouldPin = this.shouldPin();
        if (this.previousShouldPin === currentShouldPin) {
            // Si l'état précédent est le même que l'état actuel, ne rien faire
            console.log('nothing to do');
            return;
        }

        this.previousShouldPin = currentShouldPin; // Mettre à jour l'état précédent

        if (!this.SP) {
            this.pinElementsOnScroll();
            console.log('createPin on resize', this.SP);
            return;
        }
        else {
            if (this.shouldPin()) {
                console.log('updatePin', this.SP);
                this.SP.kill();
                this.SP = null;
                this.pinElementsOnScroll();
            }
            else {
                console.log('kill on resize', this.SP);
                this.SP.kill();
                this.SP = null;
            }
        }
    }
}

export default {
    init() {
        const textImageModules = document.querySelectorAll('.module-text-image.--has-img');

        if (!textImageModules.length) return;

        textImageModules.forEach((section) => {
            new TextImage(section);
        });
    },
}