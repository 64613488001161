import 'select2';

class GoogleTranslate {
    init() {
        $('document').ready(function () {
            // Change the default language text to its first two letters
            var checkExist = setTimeout(function () {
                var select = document.querySelector('.goog-te-combo');
                if (select) {
                    var firstOption = select.options[select.selectedIndex];
                    if (firstOption) {
                    console.log(firstOption.value);
                        var selectedOption = $('#language-select option[value="' + firstOption.value + '"]');
                        console.log(selectedOption);
                        var dataLang = selectedOption.data('lang');
                        console.log(dataLang);
                        $('#selected-lang').show().text(dataLang);
                                                $('#language-select').val(firstOption.value).trigger('change.select2');

                    }
                        clearInterval(checkExist);

                }
            }, 600);

            // RESTYLE THE DROPDOWN MENU
            $('#google_translate_element').on('click', function () {
                // Change font family and color
                $('iframe')
                    .contents()
                    .find(
                        '.goog-te-menu2-item div, .goog-te-menu2-item:link div, .goog-te-menu2-item:visited div, .goog-te-menu2-item:active div, .goog-te-menu2 *'
                    )
                    .css({
                        color: '#42403B',
                        'font-family': 'Lexend',
                        width: '100%',
                    });
                // Change menu's padding
                $('iframe')
                    .contents()
                    .find('.goog-te-menu2-item-selected')
                    .css('display', 'none');

                // Change menu's padding
                $('iframe').contents().find('.goog-te-menu2').css('padding', '0px');

                // Change the padding of the languages
                $('iframe')
                    .contents()
                    .find('.goog-te-menu2-item div')
                    .css('padding', '20px');

                // Change the width of the languages
                $('iframe').contents().find('.goog-te-menu2-item').css('width', '100%');
                $('iframe').contents().find('td').css('width', '100%');

                // Change hover effects
                $('iframe')
                    .contents()
                    .find('.goog-te-menu2-item div')
                    .hover(
                        function () {
                            $(this)
                                .css('background-color', '#4385F5')
                                .find('span.text')
                                .css('color', 'white');
                        },
                        function () {
                            $(this)
                                .css('background-color', 'white')
                                .find('span.text')
                                .css('color', '#544F4B');
                        }
                    );

                // Change Google's default blue border
                $('iframe').contents().find('.goog-te-menu2').css('border', 'none');

                // Change the iframe's box shadow
                $('.goog-te-menu-frame').css(
                    'box-shadow',
                    '0 16px 24px 2px rgba(0, 0, 0, 0.14), 0 6px 30px 5px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(0, 0, 0, 0.3)'
                );

                // Change the iframe's size and position?
                $('.goog-te-menu-frame').css({
                    height: '100%',
                    width: '100%',
                    top: '0px',
                });
                // Change iframes's size
                $('iframe').contents().find('.goog-te-menu2').css({
                    height: '100%',
                    width: '100%',
                });
            });
            $('#language-select').select2();
            $('.select2-container ').hide();
            $('#selected-lang').on('click', function () {
                //$('#selected-lang').hide();
                $('#language-select').show().select2('open');
            });

            $('#language-select').on('change', function () {
                var value = $('#language-select').val();
                if (value) {
                    var event = document.createEvent('HTMLEvents');
                    event.initEvent('change', true, true);
                    $('.goog-te-combo').val(value);
                    document.getElementsByClassName('goog-te-combo')[0].dispatchEvent(event);

                    var selectedOption = $('#language-select option:selected');
                    var dataLang = selectedOption.data('lang');
                    $('#selected-lang').show().text(dataLang);
                    $('#language-select').hide();
                }
            });

            // Masquer l'élément Google Translate original
            $('#google_translate_element').hide();
        });
    }
}

export default new GoogleTranslate();
