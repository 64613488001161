// import external dependencies
import 'jquery';
import 'jquery.easing';
import 'what-input/dist/what-input.min.js';
import 'simplebar';
import ResizeObserver from 'resize-observer-polyfill';

// Import custom events
import { initResizeEnd, initScrollEnd } from './utils/customEvents';

window.ResizeObserver = ResizeObserver;

// Import everything from autoload
;

// import local dependencies
import Router from './utils/Router';
import common from './routes/common';
import home from './routes/home';
import pageTemplateContact from './routes/contact';
import postTypeArchiveTemoignage from './routes/archive-temoignage';

// Initialize custom events
initResizeEnd();
initScrollEnd();

/** Populate Router instance with DOM routes */
const routes = new Router({
    home,
    pageTemplateContact,
    postTypeArchiveTemoignage,
    common,
});

// Event listeners
document.addEventListener('DOMContentLoaded', () => {
    console.log('DOMContentLoaded event triggered');
    routes.readyEvents();
});
window.addEventListener('load', () => {
    console.log('load event triggered');
    routes.loadEvents();
});
