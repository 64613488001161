import $ from 'jquery';
// Assurez-vous que jsSocials est correctement importé, soit via un import npm si disponible, soit en l'incluant dans votre HTML
import 'jssocials'; // Uncomment if jsSocials is available via npm

export default {
    init() {
        const sharingTools = $('#js-sharing_tools');

        if (sharingTools.length) {
            let icones = $('.js-section-share').data('iconePartage');
            icones += '';

            const shares = icones.split('-').map(share => {
                if (share === 'email') {
                    return {
                        share: 'email',
                        url: $('#js-email-content').data('emailContent'),
                        showLabel: false,
                        showCount: false,
                    };
                }
                return share;
            });

            sharingTools.jsSocials({
                showLabel: false,
                showCount: false,
                shareIn: 'popup',
                shares: shares,
            });

            console.log('Social icon initialized');
        }
    },
};