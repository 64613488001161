import gsap from 'gsap';

class SearchModal {
    constructor() {
        this.searchModal = document.querySelector('.search-modal');
        this.searchFieldset = this.searchModal.querySelector('fieldset');
        this.searchOpenTrigger = '[data-open-search-modal="true"]';
        this.searchCloseTrigger = '[data-close-search-modal="true"]';
        this.lastFocusedElement = null;
    }

    init() {
        document.addEventListener('click', (e) => {
            if (e.target.closest(this.searchOpenTrigger)) {
                this.lastFocusedElement = e.target.closest(this.searchOpenTrigger);
                this.toggleDisplay(true, () => {
                    this.toggleInnerAnimation(true, () => {
                        this.focusFirstElement();
                        document.documentElement.setAttribute('data-search-modal-open', true);
                    });
                });
            }

            if (e.target.closest(this.searchCloseTrigger)) {
                this.toggleInnerAnimation(false, () => {
                    this.toggleDisplay(false, () => {
                        this.searchModal.style.display = 'none';
                        document.documentElement.setAttribute('data-search-modal-open', false);
                        this.focusLastElement();
                    });
                });
            }
        });
    }

    toggleDisplay(display, callback) {
        if (!this.searchModal) return;

        if (display) {
            this.searchModal.style.display = 'flex';
        }

        gsap.fromTo(this.searchModal,
            {
                opacity: display ? 0 : 1,
                scale: display ? 0.8 : 1,
                y: display ? 100 : 0,
            },
            {
                duration: 0.5,
                opacity: display ? 1 : 0,
                y: display ? 0 : -100,
                scale: display ? 1 : 0.8,
                ease: 'power2.inOut',
                onComplete: callback,
            });
    }

    toggleInnerAnimation(display, callback) {

        gsap.fromTo(this.searchFieldset,
            {
                opacity: display ? 0 : 1,
                x: display ? -100 : 0,
            },
            {
                opacity: display ? 1 : 0,
                x: display ? 0 : 100,
                stagger: display ? -0.15 : 0.15,
                duration: 0.4,
                ease: display ? 'power1.out' : 'power1.in',
                onComplete: callback,
            });
    }

    focusFirstElement() {
        const firstFocusableElement = this.searchModal.querySelector('button, [href], input, select, textarea, [tabindex]:not([tabindex="-1"])');
        if (firstFocusableElement) {
            firstFocusableElement.focus();
        }
    }

    focusLastElement() {
        if (this.lastFocusedElement) {
            this.lastFocusedElement.focus();
        }
    }
}

export default new SearchModal();
