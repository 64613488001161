import 'jquery.cookie';
import 'slick-carousel';
import gsap from 'gsap';
import {ScrollTrigger} from 'gsap/ScrollTrigger';
import scrollAnimations from '../utils/scroll-animations';
import archiveTemoignage from '../routes/archive-temoignage';

/* global dataterra */
gsap.registerPlugin(ScrollTrigger);

(function ($) {
    // Variables globales
    // const burgerBreakpoint = 1200;

    const $body = $('body');
    const $window = $(window);
    const $header = $('header');
    const $backToTop = $('.js-back-to-top');

    // Breakpoints
    const portrait = 640;
    const tablet = 760;
    const landscape = 960;
    const desktopSm = 1080;
    const desktop = 1200;
    const large = 1440;
    const wide = 1680;

    // Transition times
    const time = 300;
    // const timeSm = 200;
    const timeLg = 600;

    var lastScrollTop = 0;

    // Initialisation
    // toggleSearchForm();
    shareToggle();
    backToTop();
    // openMobileNav();
    // highlight('[data-highlight="1"]');
    popinRessources();

    // Chargement
    $window.on('load', function () {
        page_loader();
        // addGformDefaultClasses();
        scrollUpDown(true);
        stickyHeader(true);

        /*
        setTimeout(function () {
            gs_reveal();
        }, 1000);
        */
    });

    // Scroll
    $window.on('scroll', function () {
        scrollUpDown(false);
    });

    // Redimensionnement
    $window.on('change resize', function () {
        // submenuDesktop(false);
        // openMobileNav();
    });

    $window.on('change resize scroll', function () {
        stickyHeader(false);
    });

    // Clics
    $(document).on('click', '.js-accordion-title', (e) => accordionToggling(e));
    $(document).on('keydown', '.js-accordion-title', function (e) {
        if (e.key === 'Enter' || e.keyCode === 13) {
            accordionToggling(e);
        }
    });
    $body.on('click', '.js-smooth-scroll', smoothScroll);
    $body.on('click', '.js-reveal-video-modal', revealVideo);
    $body.on('click', '.js-close-modal', closeModal);

    // Fonctions
    function page_loader() {
        if (!document.querySelector('.page-loader')) return;

        gsap.to('.page-loader', {
            duration: 1,
            delay: 1,
            ease: 'power2',
            overwrite: 'auto',
            autoAlpha: 0,
            display: 'none',
        });
    }

    function stickyHeader() {
        var $scrollTop = $window.scrollTop();

        if ($scrollTop > $(window).height() / 2) {
            $header.addClass('sticky');
            document.documentElement.setAttribute('data-header-sticky', true);
        } else {
            $header.removeClass('sticky');
            document.documentElement.setAttribute('data-header-sticky', false);
        }

        if ($scrollTop > 0) {
            $header.addClass('pinned');
            document.documentElement.setAttribute('data-header-pinned', true);
        } else {
            $header.removeClass('pinned');
            document.documentElement.setAttribute('data-header-pinned', false);
        }
    }

    function scrollUpDown(onLoadEvent) {
        var st = $window.scrollTop();

        if (st > lastScrollTop) {
            document.documentElement.setAttribute('data-scroll-direction', 'down');
            $header.addClass('down');
            if (st > $window.height()) {
                $backToTop.addClass('active');
            } else {
                $backToTop.removeClass('active');
            }
        } else {
            document.documentElement.setAttribute('data-scroll-direction', 'up');
            if (st > $window.height()) {
                $backToTop.addClass('active');
            } else {
                $backToTop.removeClass('active');
            }
            $header.removeClass('down');
        }

        lastScrollTop = st;

        if (onLoadEvent === true) {
            $header.removeClass('down');
        }
    }

    function backToTop() {
        $backToTop.on('click', function (e) {
            e.preventDefault();
            $('html, body').animate(
                {
                    scrollTop: 0,
                },
                timeLg * 2,
                'easeInOutQuart'
            );
        });
    }

    // http://tracker.lajungle.fr/issues/33295

    function shareToggle() {
        const $shareBtn = $('.js-share-open-list');
        $shareBtn.next().css('width', 0);

        $shareBtn.on('click', function () {
            var toggleWidth = $(this).next().width() === 176 ? '0' : '176px';
            $(this).toggleClass('opened');
            $(this).next().animate({
                width: toggleWidth,
            });
            if ($(this).hasClass('opened')) {
                $(this).children('i').addClass('icon-close');
            } else {
                //$(this).children('i').animate({ opacity: 1,  marginLeft: 5 });
                $(this).children('i').removeClass('icon-close');
            }
        });
    }

    // Initialisation par défaut
    $('.js-open-modal').each(function (modalIndex, modal) {
        var $currentModal = $(modal);
        var target = $currentModal.data('target');
        var wrapperClass = $currentModal.data('wrapper-class');
        var modalLabel = $currentModal.data('modal-label');
        var closeLabel = $currentModal.data('close-label');
        $currentModal.modaal({
            // config
            contentSource: target,
            animationSpeed: 300,
            hideClose: false,
            overlayClose: true,
            accessibleTitle: modalLabel,
            startOpen: false,
            customClass: wrapperClass,
            backgroundScroll: false,
            closeAriaLabel: closeLabel,
            // css
            background: '#000',
            overlayOpacity: 0.8,
        });
    });

    function openModal($elem) {
        $elem.removeClass('hidden');
        $body.addClass('modal-opened');
    }

    function closeModal() {
        $(this).closest('.modal').addClass('hidden');
        $('.modal iframe').attr('src', ' ');
        $('.js-reveal-video-modal.in-popin').show();
        $body.removeClass('modal-opened');
        document.documentElement.style.overflow = '';
    }

    function popinRessources() {
        const itemClass = '.ressource-item';
        const $item = $(
            '.ressource-list ' + itemClass + ', .result-list ' + itemClass
        );

        if ($item.length === 0) {
            return false;
        }

        $('.js-ressource-item').each(function () {
            $(this).on('click', function (evt) {
                const $this = $(this);
                const $currentModal = $('#js-ressources-modal');
                const $itemCurrentModal = $currentModal.find(itemClass);
                const index = $this.attr('data-modal-index');

                evt.preventDefault();

                document.documentElement.style.overflow = 'hidden';

                // console.log('data-modal-index', index);
                // // console.log('data-index', indexModal);
                // if ( $itemCurrentModal.attr('data-index') === undefined || $itemCurrentModal.attr('data-index').length === 0 ) {
                //   return false;
                // }

                $itemCurrentModal.each(function () {
                    $(this).hide();

                    if ($(this).attr('data-index') === index) {
                        openModal($currentModal);
                        $(this).show();
                    }
                });
            });
        });
    }

    //=====================================================
    //=====================================================
    //      ANIMATIONS
    //=====================================================
    //=====================================================

    //
    /*
     * animate number
     * accordions
     * highlight
     */

    // animate number
    function formatNumber(number, thousandSep) {
        var parts = number.toString().split('.');
        parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, thousandSep);
        return parts;
    }

    $('.js-numerical-animation').each(function (index, elem) {
        var $current = $(elem);
        var target = $current.text();

        function launch() {
            var currentScroll =
                $('html, body').scrollTop() + $window.outerHeight();
            var limit = $current.offset().top + 150;

            if (currentScroll > limit && !$current.hasClass('finished')) {
                // if reached scroll limit
                $current.addClass('finished');

                jQuery({
                    Counter: 0,
                }).animate(
                    {
                        Counter: target,
                    },
                    {
                        duration: 1500,
                        easing: 'swing',
                        step: function () {
                            $current.text(Math.ceil(this.Counter));
                            $current.text(formatNumber($current.text(), ' '));
                        },
                        complete: function () {
                            $current.text(target);
                            $current.text(formatNumber($current.text(), ' '));
                        },
                    }
                );
            }
        }

        setTimeout(function () {
            launch();
        }, 500);
        $window.on('scroll', function () {
            launch();
        });
    });

    // accordions
    function accordionToggling(e) {
        e.preventDefault();
        const animationSpeed = time;
        const $clickedTitle = $(e.currentTarget);

        $clickedTitle.toggleClass('active');
        $clickedTitle.next().slideToggle(animationSpeed);
    }

    /*
    function highlight(element) {
        const parent = '.js-highlight';
        const $item = $(element);

        if (!$body.hasClass('.is-desktop') && $(parent).length === 0) {
            return false;
        }

        $item.each(function () {
            const $this = $(this);

            $this
                .on('mouseenter focus', function () {
                    $this.closest(parent).addClass('is-highlighted');
                })
                .on('mouseleave focusOut', function () {
                    //setTimeout(function() {
                    $this
                        .closest(parent)
                        .delay(timeSm)
                        .removeClass('is-highlighted');
                    //}, time)
                });
        });
    }
    */

    //=====================================================
    //=====================================================
    //      DIAPORAMA / VIDEO
    //=====================================================
    //=====================================================

    function revealVideo(e) {
        e.preventDefault();

        const $this = $(this);
        var theModal = $this.hasClass('in-popin')
            ? $($this.data('toggle'))
            : $this.data('target');
        var iframe = $this.hasClass('in-popin')
            ? $($this.data('target'))
            : $(theModal + ' iframe');
        var videoSRC = $this.attr('src');
        var embedUrl = 'https://www.youtube.com/embed/';
        var videoSRCauto =
            videoSRC.indexOf(embedUrl) != -1
                ? videoSRC + '?autoplay=1'
                : embedUrl + videoSRC + '?autoplay=1';

        $(theModal).removeClass('hidden');

        if ($this.hasClass('in-popin')) {
            $this.fadeOut();
        }

        iframe.attr('src', videoSRCauto);

        $(theModal).on('hidden', function () {
            iframe.attr('src', '');
        });
    }

    // Params

    // schema sliders

    var $btn_prev =
        '<button type="button" class="slick-prev"><i class="icon-arrow-left"></i></button>';
    var $btn_next =
        '<button type="button" class="slick-next"><i class="icon-arrow-right"></i></button>';
    var el = $('.js-slick-slider');

    if (el.length) {
        el.each(function () {
            let $this = $(this),
                count_mobile =
                    $this.attr('data-mobile') === undefined ||
                    $this.attr('data-mobile') === ''
                        ? 1
                        : $this.attr('data-mobile'),
                count_portrait =
                    $this.attr('data-portrait') === undefined ||
                    $this.attr('data-portrait') === ''
                        ? 2
                        : $this.attr('data-portrait'),
                count_tablet =
                    $this.attr('data-tablet') === undefined ||
                    $this.attr('data-tablet') === ''
                        ? count_portrait
                        : $this.attr('data-tablet'),
                count_landscape =
                    $this.attr('data-landscape') === undefined ||
                    $this.attr('data-landscape') === ''
                        ? 3
                        : $this.attr('data-landscape'),
                count_sm_desktop =
                    $this.attr('data-sm-desktop') === undefined ||
                    $this.attr('data-sm-desktop') === ''
                        ? count_landscape
                        : $this.attr('data-sm-desktop'),
                count_desktop =
                    $this.attr('data-desktop') === undefined ||
                    $this.attr('data-desktop') === ''
                        ? 4
                        : $this.attr('data-desktop'),
                count_large =
                    $this.attr('data-large') === undefined ||
                    $this.attr('data-large') === ''
                        ? count_desktop
                        : $this.attr('data-large'),
                count_wide =
                    $this.attr('data-wide') === undefined ||
                    $this.attr('data-wide') === ''
                        ? count_desktop
                        : $this.attr('data-wide'),
                $dots = $this.attr('data-dots') === '1' ? true : false,
                $arrows =
                    $this.attr('data-arrows') === '0' ||
                    $this.attr('data-arrows') === undefined
                        ? false
                        : true,
                $autoplay = $this.attr('data-autoplay') === '1' ? true : false,
                $infinite =
                    $this.attr('data-infinte') === '0' ||
                    $this.attr('data-infinte') === undefined
                        ? false
                        : true;

            el.slick({
                infinite: $infinite,
                slidesToShow: count_mobile,
                slidesToScroll: 1,
                prevArrow: $btn_prev,
                nextArrow: $btn_next,
                arrows: $arrows,
                dots: $dots,
                autoplay: $autoplay,
                speed: timeLg,
                autoplaySpeed: 5000,
                mobileFirst: true,
                responsive: [
                    {
                        breakpoint: portrait,
                        settings: {
                            slidesToShow: count_portrait,
                        },
                    },
                    {
                        breakpoint: tablet,
                        settings: {
                            slidesToShow: count_tablet,
                        },
                    },
                    {
                        breakpoint: landscape,
                        settings: {
                            slidesToShow: count_landscape,
                        },
                    },
                    {
                        breakpoint: desktopSm,
                        settings: {
                            slidesToShow: count_sm_desktop,
                        },
                    },
                    {
                        breakpoint: desktop,
                        settings: {
                            slidesToShow: count_desktop,
                        },
                    },
                    {
                        breakpoint: large,
                        settings: {
                            slidesToShow: count_large,
                        },
                    },
                    {
                        breakpoint: wide,
                        settings: {
                            slidesToShow: count_wide,
                        },
                    },
                ],
            });
        });
    }

    /* Slider home */
    let schemaSlider = $('#js-schema-slider-for').slick({
        infinite: false,
        fade: true,
        arrows: false,
        dots: true,
        mobileFirst: true,
        speed: 1000,
        autoplay: false,
        responsive: [
            {
                breakpoint: portrait,
                settings: {
                    dots: false,
                },
            },
        ],
    });

    const $wheel = $('#js-schema-wheel');
    const $wheelNav = $('.js-schema-slider-nav');

    schemaSlider.on(
        'beforeChange',
        function (event, slick, currentSlide, nextSlide) {
            $('.js-schema-slider-nav').removeClass('current');
            $('.js-schema-slider-nav[data-index=' + nextSlide + ']').addClass(
                'current'
            );
        }
    );

    $wheelNav.on('click', function () {
        let $btn = $(this);
        let wheelData = $(this).attr('data-wheel');

        let offsetY =
            $window.width() >= tablet
                ? $wheel.offset().top
                : $btn.closest('.schema-nav').offset().top;

        $('.js-schema-slider-nav').removeClass('current');
        $btn.addClass('current');
        schemaSlider.slick('slickGoTo', $btn.data('index'));
        $wheel.attr('data-wheel-current', wheelData);

        $('html, body').animate(
            {
                scrollTop: offsetY - $header.height(),
            },
            timeLg * 2,
            'easeInOutQuart'
        );
    });

    $wheelNav.on('click', function () {
    });

    var $diaporama = $('.js-diaporama-list');

    var diaporamaSettings = {
        speed: time,
        infinite: true,
        prevArrow:
            '<button type="button" class="diaporama-arrow prev"><i class="icon-arrow-left"></i></button>',
        nextArrow:
            '<button type="button" class="diaporama-arrow next"><i class="icon-arrow-right"></i></button>',
    };

    $diaporama.slick(diaporamaSettings);
    $diaporama.on(
        'beforeChange',
        function (event, {slideCount: count}, currentSlide, nextSlide) {
            var selectors = [nextSlide, nextSlide - count, nextSlide + count]
                .map((n) => `[data-slick-index="${n}"]`)
                .join(', ');
            $('.slick-now').removeClass('slick-now');
            $(selectors).addClass('slick-now');
        }
    );

    $('[data-slick-index="0"]').addClass('slick-now');

    /*
     ** Sliders ACTUS / Zone de rebond / Pôles
     */

    const $sliders = $('.js-slider-news');

    var $sliderNewsSettings = {
        infinite: true,
        centerMode: false,
        speed: timeLg,
        slidesToShow: 1,
        slidesToScroll: 1,
        prevArrow: $btn_prev,
        nextArrow: $btn_next,
        mobileFirst: true,
        responsive: [
            {
                breakpoint: portrait,
                settings: {
                    slidesToShow: 2,
                },
            },
            {
                breakpoint: desktop,
                settings: {
                    slidesToShow: 3,
                },
            },
        ],
    };

    $sliders.each(function () {
        const $sliderNews = $(this);

        if ($sliderNews.length > 0 && $sliderNews.children().length > 1) {
            // if ($(window).width() < desktop) {
            $sliderNews.slick($sliderNewsSettings);
            // Recours à de la syntaxe ES6
            $sliderNews.on(
                'beforeChange',
                function (
                    event,
                    {slideCount: count},
                    currentSlide,
                    nextSlide
                ) {
                    var selectors = [
                        nextSlide,
                        nextSlide - count,
                        nextSlide + count,
                    ]
                        .map((n) => `[data-slick-index="${n}"]`)
                        .join(', ');
                    $('.slick-now').removeClass('slick-now');
                    $(selectors).addClass('slick-now');
                }
            );

            $('[data-slick-index="0"]').addClass('slick-now');
            // }

            /*
enquire.register('screen and (min-width: ' + desktop + 'px)', {

  unmatch: function() {

    if (!$sliderNews.hasClass('slick-initialized')) {
      $sliderNews.slick($sliderNewsSettings);
    }
  },

  match: function() {
    if ($sliderNews.hasClass('slick-initialized')) {
      $sliderNews.slick('unslick');
    }
  }
});
*/
        }
    });

    //=====================================================
    //=====================================================
    //      UTILITIES
    //=====================================================
    //=====================================================
    /*
     * Viewport detection
     * ie11 detection
     * Object-fit polyfill
     * Smooth scroll
     * Equal height
     * Gravity forms
     */

    // ie11 detection
    if (
        /MSIE 9/i.test(navigator.userAgent) ||
        /rv:11.0/i.test(navigator.userAgent)
    ) {
        $body.addClass('ie11');
    }

    // Smooth scroll
    function smoothScroll(e) {
        e.preventDefault();
        var href = $(this).attr('href');
        var anchorOffset = $(href).offset().top;
        const gap = 40; // laisser de l'espace vide
        var headerHeight = $('.header-global-wrapper').outerHeight();
        var target = anchorOffset - gap - headerHeight;
        $('html,body').stop().animate(
            {
                scrollTop: target,
            },
            1000
        );
        return false;
    }


    /*
    // Gravity forms : ajout d'une class spécifique pour chaque type de champ
    function addGformDefaultClasses() {
        // Text
        $('.gform_wrapper .ginput_container_text').each(function (index, el) {
            $(el).closest('.gfield').addClass('type-text');
        });
        // Email
        $('.gform_wrapper .ginput_container_email').each(function (index, el) {
            $(el).closest('.gfield').addClass('type-email');
        });
        // Phone
        $('.gform_wrapper .ginput_container_phone').each(function (index, el) {
            $(el).closest('.gfield').addClass('type-phone');
        });
        // Number
        $('.gform_wrapper .ginput_container_number').each(function (index, el) {
            $(el).closest('.gfield').addClass('type-number');
        });
        // Password
        $('.gform_wrapper .ginput_container_password').each(function (
            index,
            el
        ) {
            $(el).closest('.gfield').addClass('type-password');
        });
        // Date
        $('.gform_wrapper .ginput_container_date').each(function (index, el) {
            $(el).closest('.gfield').addClass('type-date');
        });
        // Radio
        $('.gform_wrapper .ginput_container_radio').each(function (index, el) {
            $(el).closest('.gfield').addClass('type-radio');
        });
        // Checkbox
        $('.gform_wrapper .ginput_container_checkbox').each(function (
            index,
            el
        ) {
            $(el).closest('.gfield').addClass('type-checkbox');
        });
        // Select
        $('.gform_wrapper .gfield:not(.address) .gfield_select').each(function (
            index,
            el
        ) {
            $(el).closest('.gfield').addClass('type-select');
        });
        // List
        $('.gform_wrapper .ginput_container_list').each(function (index, el) {
            $(el).closest('.gfield').addClass('type-list');
        });
        // File
        $('.gform_wrapper .ginput_container_fileupload').each(function (
            index,
            el
        ) {
            $(el).closest('.gfield').addClass('type-file');
        });
        // Textarea
        $('.gform_wrapper .ginput_container_textarea').each(function (
            index,
            el
        ) {
            $(el).closest('.gfield').addClass('type-textarea');
        });
        // Recaptcha
        $('.gform_wrapper .ginput_recaptcha').each(function (index, el) {
            $(el).closest('.gfield').addClass('type-recaptcha');
        });
        // Complex field
        $('.gform_wrapper .ginput_complex, .gform_wrapper .clear-multi').each(
            function (index, el) {
                $(el).closest('.gfield').addClass('type-complex');
            }
        );
        // Complex field : name
        $('.gform_wrapper .ginput_container_name').each(function (index, el) {
            $(el).closest('.gfield').addClass('type-name');
        });
        // Complex field : adress
        $('.gform_wrapper .ginput_container_address').each(function (
            index,
            el
        ) {
            $(el).closest('.gfield').addClass('type-address');
        });
        // Complex field  : hour / date
        $('.gform_wrapper .clear-multi').each(function (index, el) {
            $(el).closest('.gfield').addClass('type-time-date');
        });
    }
    */

    function checkInput($elToCheck, $elToAffect) {
        var value = $elToCheck.val() === undefined ? 0 : $elToCheck.val();

        // // console.log('value: ' + value, 'length: ' + value.length)

        if (value.length > 0) {
            $elToAffect.addClass('floating');
            // // console.log('Si > 0, $elToAffect', $elToAffect)
        } else {
            $elToAffect.removeClass('floating');
            // // console.log('else $elToAffect', $elToAffect)
        }
    }

    const $fields = $('.gfield');

    if ($fields.length > 0) {
        $fields.each(function () {
            const $field = $(this);

            if (
                $field.hasClass('.type-file') ||
                $field.hasClass('.type-select')
            ) {
                return false;
            }

            const $label = $field.find('label');
            const $input = $field.find('.ginput_container').children();

            checkInput($input, $field);
            checkInput($input, $label);

            $input
                .on('focus', function () {
                    var $inputId = $(this).attr('id');
                    var $labelFor = $label.attr('for');

                    if ($inputId === $labelFor) {
                        $label.addClass('floating');
                        $field.addClass('floating');
                    }
                })
                .on('blur change submit', function () {
                    var $inputId = $(this).attr('id');
                    var $labelFor = $label.attr('for');

                    if ($inputId === $labelFor) {
                        checkInput($input, $field);
                        checkInput($input, $label);
                    }
                });
        });
    }

    /*
    // GSAP
    function gs_reveal() {
        const $gs_row_list = $('.gs_row, .gform_fields');
        const $gs_item = $(
            'gs_reveal, .section-title, .sponsor-list, .module-wysiwyg .shell > div'
        );

        if ($gs_row_list.length === 0) {
            return false;
        } else {
            $gs_row_list.each(function () {
                const $items = $(this).children();

                if ($items.length === 0 || $items.hasClass('gs_exclude')) {
                    return false;
                }

                $items.each(function ($i, $item) {
                    gs_hide($item);

                    ScrollTrigger.create({
                        // // markers: true,
                        trigger: $item,
                        onEnter: function () {
                            animateFrom($i, $item);
                        },
                        onLeaveBack: function () {
                            gs_hide($item);
                        }, // assure that the element is hidden when scrolled into view
                    });
                });
            });
        }

        if ($gs_item.length === 0) {
            return false;
        } else {
            $gs_item.each(function ($i, $item) {
                gs_hide($item);

                ScrollTrigger.create({
                    // // markers: true,
                    trigger: $item,
                    onEnter: function () {
                        animateFrom(0, $item);
                    },
                    onLeaveBack: function () {
                        gs_hide($item);
                    }, // assure that the element is hidden when scrolled into view
                });
            });
        }
    }

    function animateFrom(index = 0, elem, direction) {
        // console.log('animateFrom elem:', elem);

        direction = direction || 1;

        var offset = 50;

        var x = 0,
            y = direction * offset;

        if (elem.classList.contains('gs_toggle-class')) {
            elem.classList.add('animate');
        } else {
            if (direction === 'left') {
                x = -offset;
                y = 0;
            } else if (direction === 'right') {
                x = offset;
                y = 0;
            }
            elem.style.transform = 'translate(' + x + 'px, ' + y + 'px)';
            elem.style.opacity = '0';

            gsap.fromTo(
                elem,
                {
                    x: x,
                    y: y,
                    autoAlpha: 0,
                },
                {
                    duration: 1,
                    x: 0,
                    y: 0,
                    delay: index * 0.1,
                    autoAlpha: 1,
                    ease: 'expo',
                    overwrite: 'auto',
                }
            );
        }
    }

    function gs_hide(elem) {
        // console.log('gs_hide elem:', elem);

        if (elem.classList.contains('gs_toggle-class')) {
            elem.classList.remove('animate');
        } else {
            gsap.set(
                elem,
                {
                    autoAlpha: 0,
                },
                {
                    duration: 1.25,
                }
            );
        }
    }

    */

    //===========================================================================
    //===========================================================================
    //      PAGINATION EN AJAX AVEC FILTRE
    //===========================================================================
    //===========================================================================

    // Si on a pas enfermé les filtre dans un wrapper js-static-filter pour les isoler du onChange
    if ($('.js-static-filter').length < 1) {
        // Bouton pour charger plus de post à la suite
        if ($('.js-load-more-button').length > 0) {
            $('.js-load-more-button').click(function (event) {
                event.preventDefault();
                sendAjaxPagination('onLoadMore');
            });
        }

        // Changement de valeur du select de secteur
        if ($('.js-theme-filter').length > 0) {
            $('.js-theme-filter').change(function () {
                sendAjaxPagination('onChange');
            });
        }

        // Changement de valeur du select de pays
        if ($('.js-pole-filter').length > 0) {
            $('.js-pole-filter').change(function () {
                sendAjaxPagination('onChange');
            });
        }

        // Changement de valeur évènements
        if ($('.js-date-filter').length > 0) {
            $('.js-date-filter').change(function () {
                sendAjaxPagination('onChange');
            });
        }
    }

    // Si on veut filtrer le contenu au moment du clic sur un bouton validé
    if ($('.js-validator-filter').length > 0) {
        // ajax sur le bouton de validation
        $('.js-validator-filter').on('click', function () {
            sendAjaxPagination('onValidate');
        });
    }

    /**
     * Ajax sur le bloc pagination s'il est présent
     * Au clic, on annule le refresh de la page et on appelle une fonction Ajax
     */
    if ($('.js-pagination-container').length > 0) {
        ajaxOnPagination();
    }

    function ajaxOnPagination() {
        $('.js-pagination-container > *').each(function (index, element) {
            if (
                $(element).hasClass('page-numbers') !== undefined &&
                !$(element).hasClass('current')
            ) {
                $(element).click(function (event) {
                    event.preventDefault();
                    var current =
                        $('.js-pagination-container .current')[0].innerHTML * 1;
                    var target = element.innerHTML;
                    if ($(element).hasClass('next')) {
                        target = current * 1 + 1;
                    } else if ($(element).hasClass('prev')) {
                        target = current * 1 - 1;
                    } else {
                        target = target * 1;
                    }
                    sendAjaxPagination('onPagination', target);
                });
            }
        });
    }

    // Si on recherche en fonction de mots-clefs seulement, on branche l'écoute sur l'input texte
    // if( $('.js-keyword-filter').length > 0) {
    //     $('.js-keyword-filter').on('keypress', function(e) {
    //         if( e.which === 13) {
    //             // on reset les filtres
    //             $(".js-pole-filter").prop("selectedIndex", 0);
    //             $(".js-year-filter").prop("selectedIndex", 0);
    //             $(".js-type-filter").prop("selectedIndex", 0);
    //             // puis requête ajax sur le nom
    //             sendAjaxPagination("searchByKeyword");
    //         }
    //     });
    // }

    // On efface le contenu des filtres pour revenir au premier
    // if( $('.js-reset-filter').length > 0 ) {
    //     $('.js-reset-filter').on('click', function() {
    //         $(".js-pole-filter").prop("selectedIndex", 0);
    //         $(".js-year-filter").prop("selectedIndex", 0);
    //         $(".js-type-filter").prop("selectedIndex", 0);
    //         $(".js-name-filter").val("");
    //         // Vider le changer de recherche par nom aussi
    //         sendAjaxPagination("onReset");
    //     })
    // }

    // les données importante au filtre
    function getFilterValue(action, targetPagination = undefined) {
        // le type d'action, qui veut que l'on reparte de 0 ou que l'on ajoute à la suite
        var offset;
        if (
            action === 'onChange' ||
            action === 'searchByKeyword' ||
            action === 'onReset' ||
            action === 'onValidate' ||
            action === 'onPagination'
        ) {
            offset = 0;
        } else if (action === 'onLoadMore') {
            offset = 1 * $('.js-ajax-anchor').data('offset');
        }
        // Dans le filtre, on récupère le nombre de post actuellement affiché, le pays, le secteur sélectionné
        var filter = {
            ppp: $('.js-ajax-anchor').data('ppp'),
            lang: $('.js-ajax-anchor').data('lang'),
            offset: offset,
            // "year": $('.js-year-filter-item.active').data("year"), // si inté nécessite champ select custom plus tard
            year: $('.js-year-filter').val(),
            // "type": $('.js-type-filter-item.active').data("type"), // si inté nécessite champ select custom plus tard
            type: $('.js-type-filter').val(),
            // "theme": $('.js-theme-filter-item.active').data("theme"), // si inté nécessite champ select custom plus tard
            theme: $('.js-theme-filter').val(),
            // "pole": $('.js-pole-filter-item.active').data("pole"), // si inté nécessite champ select custom plus tard
            pole: $('.js-pole-filter').val(),
            // "date": $('.js-date-filter-item.active').data("date"), // si inté nécessite champ select custom plus tard
            date: $('.js-date-filter').val(),
            // "service": $('.js-service-filter-item.active').data("service"), // si inté nécessite champ select custom plus tard
            // "service": $('.js-service-filter').val(),
            keyword: $('.js-keyword-filter').val(),
            action: action,
        };

        if (action === 'onPagination' && targetPagination !== undefined) {
            filter.targetPagination = targetPagination;
        }

        return filter;
    }

    // On envoi le filtrage par tag et pagination
    function sendAjaxPagination(action, targetPagination = undefined) {
        // On envoi les filtres
        var data = {
            filter: getFilterValue(action, targetPagination),
            action: $('.js-ajax-anchor').data('action'), // vise le controller AjaxListeController
            method: $('.js-ajax-anchor').data('method'), // vise la fonction getRessourcesAction
        };

        $.ajax({
            url: dataterra.ajaxurl,
            dataType: 'json',
            data: data,
            beforeSend: function () {
                $('.js-ajax-loader').show();
                $('html, body').animate(
                    {
                        scrollTop: $('.filters').offset().top,
                    },
                    timeLg * 2,
                    'easeInOutQuart'
                );
            },
        }).done(function (data) {
            scrollAnimations.animateWithObserverStagger();

            $('.js-ajax-loader').hide();

            if (action === 'onLoadMore') {
                // si on veut simplement les posts suivant avec le même filtre
                $('.js-list-container')[0].innerHTML += data.html; // on injecte le contenu à la fin
                // on remplace toutes les nouvelles popin par les x nouveaux
                if (data.htmlPopin !== undefined) {
                    $('.js-popin-container')[0].innerHTML = data.htmlPopin;
                    archiveTemoignage.init();
                }
                // on met data-offset au nombre de post que l'on a récupéré + le nombre précédent
                var offset = $('.js-ajax-anchor').data('offset');
                $('.js-ajax-anchor').data(
                    'offset',
                    1 * (offset + 1 * data.size)
                );
                // Si jamais on a récupéré moins de post que ppp, alors on cache le bouton load More
                if (data.size < $('.js-ajax-anchor').data('ppp')) {
                    $('.js-load-more-button').fadeOut();
                }
            } else if (
                action === 'onChange' ||
                action === 'onSearchByName' ||
                action === 'onValidate' ||
                action === 'onReset' ||
                action === 'onPagination' ||
                action === 'onSearchKeyword'
            ) {
                // si on change le filtre ou la méhode de recherche

                if (data.size < $('.js-ajax-anchor').data('ppp')) {
                    // Si jamais on a récupéré moins de post, alors on cache le bouton load More
                    $('.js-load-more-button').fadeOut();
                } else {
                    // On fait réapparaitre le bouton si jamais il était caché
                    $('.js-load-more-button').fadeIn();
                }
                // on remplace tout par les x nouveaux
                $('.js-list-container')[0].innerHTML = data.html;


                // on remplace toutes les nouvelles popin par les x nouveaux
                if (data.htmlPopin !== undefined) {
                    $('.js-popin-container')[0].innerHTML = data.htmlPopin;
                    archiveTemoignage.init();
                }
                // on met data-offset au nombre de post que l'on a récupéré
                $('.js-ajax-anchor').data('offset', 1 * data.size);
                scrollAnimations.animateChild();
                scrollAnimations.animateOnScrollScrub();
                scrollAnimations.animateWithObserverStagger();


                if (
                    data.pagination !== undefined &&
                    $('.js-pagination-container').length > 0
                ) {
                    $('.js-pagination-container')[0].innerHTML =
                        data.pagination;
                    ajaxOnPagination();
                }
            }

            popinRessources();


        });
    }

    document.addEventListener('submit', function (e) {
        if (e.target.matches('.js-form-newsletter')) {
            e.preventDefault();

            var emailField = e.target.querySelector('#subscribe-newsletter');
            var emailValue = emailField.value;

            if (emailValue === '') {
                emailField.closest('.gfield').classList.add('gfield_error');
            } else {
                ajaxNewsletter(emailValue);
                emailField.value = '';
            }
        }
    });

    function ajaxNewsletter(email) {
        $.ajax({
            url: dataterra.ajaxurl,
            data: {
                email: email,
                action: 'newsletter',
                method: 'registernewsletter',
            },
            beforeSend: function () {
                $('.js-ajax-loader').show();
            },
        }).done(function () {
            $('.js-ajax-loader').hide();
            $('.js-form-newsletter').hide();
            document.querySelector('.js-ajax-newsletter-success-message').style.display = 'flex';
        });
    }
})(jQuery);
