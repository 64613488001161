import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

gsap.registerPlugin(ScrollTrigger);

class ScrollAnimations {
    constructor() {
        this.appAnimationsEnabled = document.body.classList.contains('accessibility_animations');
    }

    init() {
        // document.addEventListener('animationsEnabled', this.initMethods.bind(this));
        // document.addEventListener('animationsDisabled', this.destroy.bind(this));

        if(document.body.classList.contains('accessibility_animations')) {
            this.initMethods();
        }
    }

    initMethods() {
        console.log('ScrollAnimations', 'initMethods');
        this.animateOnScrollScrub();
        this.animateWithStagger();
        this.animateWithObserverStagger(); // Nouvelle méthode ajoutée
    }

    animateOnScrollScrub() {
        const elements = document.querySelectorAll('[data-gsap-scrub="true"]');

        if (!elements.length) return;

        elements.forEach((element) => {
            gsap.fromTo(element.children,
                {
                    opacity: 0,
                    scale: 0.5,
                },
                {
                    opacity: 1,
                    scale: 1,
                    stagger: 0.2,
                    ease: 'power1.out',
                    scrollTrigger: {
                        trigger: element,
                        start: 'top 100%',
                        end: 'bottom 60%',
                        scrub: true,
                    },
                }
            );
        });
    }

    animateWithStagger() {
        const elements = document.querySelectorAll('[data-gsap-stagger="true"]');

        if (!elements.length) return;

        elements.forEach((element) => {
            element.gsapStart = element.dataset.gsapStart || 'top 90%';

            gsap.set(element.children, { opacity: 0, y: 50 });

            ScrollTrigger.batch(element.children, {
                start: element.gsapStart,
                end: 'bottom 10%',
                once: true, // Assure que l'animation ne se déclenche qu'une seule fois
                onEnter: (batch) => this.animateChild(batch),
            });
        });
    }

    animateChild(child, direction) {
        direction = direction || 1;
        gsap.to(child, {
            duration: 0.8,
            opacity: direction === -1 ? 0 : 1,
            y: direction === -1 ? 50 : 0,
            ease: direction === -1 ? 'back.in' : 'back.out',
            stagger: 0.1,
        });
    }

    // Méthode pour animer avec IntersectionObserver et stagger
    animateWithObserverStagger() {
        const elements = document.querySelectorAll('[data-animate]:not([data-animate="false"])');
        const options = {
            rootMargin: '0px 0px -100px 0px',
            threshold: 0.125, // Ajustez cette valeur selon vos besoins (par exemple, 0.5 pour 50%)
        };

        if (!elements.length) return;

        // Fonction pour animer plusieurs éléments avec stagger
        const animateElements = (elements) => {
            gsap.fromTo(elements,
                { opacity: 0, y: 50 }, // état initial
                { opacity: 1, y: 0, duration: 0.8, stagger: 0.1, ease: 'back.out' } // animation avec décalage
            );
        };

        // Créer un nouvel IntersectionObserver
        let observer = new IntersectionObserver((entries, observer) => {
            const visibleElements = [];

            entries.forEach(entry => {
                if (entry.isIntersecting) {
                    visibleElements.push(entry.target); // Ajouter les éléments visibles dans le tableau
                }
            });

            if (visibleElements.length > 0) {
                animateElements(visibleElements); // Animer les éléments visibles avec stagger
                visibleElements.forEach(el => observer.unobserve(el)); // Stop observer après l'animation
            }
        }, options);

        // Observer tous les éléments avec data-attribute [data-animate]
        elements.forEach(element => {
            observer.observe(element);
        });
    }

    destroy() {
        console.group('ScrollAnimations', 'destroy');
        ScrollTrigger.getAll().forEach((trigger) => {
            trigger.kill();
            trigger.trigger.removeAttribute('style');
        });
        console.groupEnd();
    }
}

export default new ScrollAnimations();
