import gsap from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import { adminbar, breakpoint } from '../utils/variables';

gsap.registerPlugin(ScrollTrigger);

export class ContactInformations {
    constructor(section) {
        this.section = section;

        if(!this.section) return;

        this.sidebar = section.querySelector('.contact-informations-sidebar');
        this.content = section.querySelector('.contact-informations-content');
        this.mm = gsap.matchMedia();
        this.offset = { y: adminbar.height + 24 };
        this.currentBreakpoint = window.innerWidth >= breakpoint.sidebar ? 'large' : 'small';
    }

    init() {
        this.pinSidebar();
        this.updateImageSidebarHeight();
        window.addEventListener('resizeEnd', this.handleResize.bind(this));
    }

    handleResize() {
        const newBreakpoint = window.innerWidth >= breakpoint.sidebar ? 'large' : 'small';
        if (newBreakpoint !== this.currentBreakpoint) {
            this.currentBreakpoint = newBreakpoint;
            this.updateImageSidebarHeight();
        }
    }

    pinSidebar() {
        if (!this.sidebar || !this.content) return;

        this.mm.add(`(min-width: ${breakpoint.sidebar}px)`, () => {
            gsap.to(this.sidebar, {
                scrollTrigger: {
                    trigger: this.content,
                    start: () => `top ${this.offset.y}px`,
                    end: () => `bottom ${this.sidebar.clientHeight + this.offset.y}px`,
                    pin: this.sidebar,
                    scrub: true,
                    onEnter: () => this.setDataPinned(true),
                    onLeave: () => this.setDataPinned(false),
                    onEnterBack: () => this.setDataPinned(true),
                    onLeaveBack: () => this.setDataPinned(false),
                },
            });
        });
    }

    setDataPinned(boolean) {
        this.sidebar.setAttribute('data-pinned', boolean);
    }

    updateImageSidebarHeight() {
        if(!this.section.getAttribute('data-flexible-formulaire') || this.section.getAttribute('data-flexible-formulaire') !== 'true') return;
        if (!this.sidebar && !this.content) return;

        const img = this.sidebar.querySelector('img');

        console.log('this.updateImageSidebarHeight()');

        if (window.innerWidth >= breakpoint.sidebar) {
            img.style.height = `${this.content.scrollHeight}px`;
            ScrollTrigger.refresh();
        }
        else {
            img.removeAttribute('style');
        }
    }
}
