import gsap from 'gsap';
import ScrollTrigger from 'gsap/ScrollTrigger';
import headerAccessibility from '../partials/header-accessibility';

gsap.registerPlugin(ScrollTrigger);
class KeyFiguresAnimator {
    constructor() {
        this.selector = '[data-js-kpi-number]';
        this.elements = document.querySelectorAll(this.selector);
    }

    init() {
        if (!this.elements.length || !headerAccessibility.animationsAreEnabled()) return;
        this.animateNumbersOnScroll();
    }

    reset() {
        if (!this.elements.length || !headerAccessibility.animationsAreEnabled()) return;
        this.initNumbersToZero();
    }

    initNumbersToZero() {
        if (!this.elements.length || !headerAccessibility.animationsAreEnabled()) return;
        this.elements.forEach((element) => {
            element.textContent = '0';
        });
    }

    animateNumbersOnScroll() {
        // Utilisez `this.elements` au lieu de `document.querySelectorAll(this.selector)`
        // pour éviter de sélectionner les éléments une deuxième fois
        gsap.to(this.elements, {
            duration: 2,
            textContent: function (index, target) {
                return target.getAttribute('data-js-kpi-number');
            },
            snap: { textContent: 1 },
            stagger: 0.1,
            scrollTrigger: {
                trigger: this.selector,
                start: 'top 75%',
                end: 'bottom 25%',
                toggleActions: 'play pause play pause',
                // once: true,
            },
            ease: 'power1.out',
        });
    }
}

export default new KeyFiguresAnimator();