// Importations des anciennes dépendances du socle gulp
import '../src/integration.js';
import '../src/jquery.ua.js';
import '../src/parallax.js';

// importation des nouvelles dépendances webpack
// import header from '../layouts/header.js';
import tools from '../utils/tools.js';
import scrollAnimations from '../utils/scroll-animations.js';
import accessibility from '../partials/header-accessibility.js';
import accordion from '../components/accordion.js';
import socialIcon from '../components/social-icon.js';
import newsCarousel from '../home/news-carousel';
import partners from '../home/partners.js';
import formDefault from '../layouts/form-default.js';
import formGravity from '../layouts/form-gravity.js';
import searchModal from '../layouts/search-modal.js';
import megaMenu from '../layouts/mega-menu.js';
import keyFigures from '../modules/key-figures.js';
import textImage from '../modules/text-image.js';
import GoogleTranslate from '../modules/gtranslate';
import {ContactInformations} from '../modules/contact-informations.js';

// Définition et exportation de l'objet par défaut
export default {
    init() {
        // header.init();
        tools.init();
        accessibility.init();
        accordion.init();
        searchModal.init();
        formDefault.init();
        formGravity.init();
        socialIcon.init();
        partners.init();
        keyFigures.reset(); // Initialise les chiffres à zéro

        console.log('common.js', 'init');
    },

    finalize() {
        // JavaScript à exécuter sur toutes les pages, après l'exécution du JS spécifique à chaque page
        console.log('common.js', 'finalize');
    },

    load() {
        if (document.querySelectorAll('.contact-informations').length > 0) {
            document.querySelectorAll('.contact-informations').forEach((section) => {
                const contactInfos = new ContactInformations(section);
                contactInfos.init();
            });
        }

        // Initialiser les animations de défilement si le loader n'existe pas
        if (!document.getElementById('home-loader')) {
            scrollAnimations.init();
        }

        // Écouter l'événement custom pour initialiser les animations de défilement
        document.addEventListener('loaderDisappeared', () => {
            console.log('Loader has disappeared, executing dependent logic.');
            scrollAnimations.init();
        });

        newsCarousel.init();
        keyFigures.init(); // Anime les chiffres
        megaMenu.init();
        textImage.init();
        GoogleTranslate.init();

    },
};