import { gsap } from 'gsap';

export class Modal {
    constructor(selector) {
        this.modalElement = !selector ? document.querySelector('.modal') : document.querySelector(selector);
        this.isOpen = false;
        this.duration = 0.3;
        this.focusSelectors = 'button, [href], input, select, textarea, [tabindex]:not([tabindex="-1"], .simplebar-content-wrapper)';
        this.lastFocusedElement = null;

        this.init();
    }

    init() {
        if (!this.modalElement) return;

        document.addEventListener('click', (event) => {
            if (event.target.closest('[data-modal-open]')) {
                this.open();
            } else if (
                event.target.closest('[data-modal-close]') ||
                (this.isOpen && !event.target.closest('[data-modal-content]'))
            ) {
                this.close();
            }
        });

        document.addEventListener('keydown', (event) => {
            if (this.isOpen) {
                if (event.key === 'Escape') {
                    this.close();
                } else if (event.key === 'Tab') {
                    this.trapFocus(event);
                }
            }
        });
    }

    open(callback, focusFirst = true) {
        if (this.isOpen) return;

        this.isOpen = true;
        this.lastFocusedElement = document.activeElement;
        
        document.documentElement.style.overflow = 'hidden';

        gsap.fromTo(this.modalElement,
            { opacity: 0 },
            {
                opacity: 1,
                duration: this.duration,
                ease: 'power2.out',
                display: 'flex',
                onComplete: () => {
                    const modalOpenedEvent = new CustomEvent('modalOpened', {
                        detail: {
                            message: 'Modal has been opened',
                            time: new Date(),
                        },
                    });

                    if (focusFirst) {
                        this.focusFirstElement();
                    }

                    if (typeof callback === 'function') {
                        callback();
                    }

                    document.dispatchEvent(modalOpenedEvent);
                },
            });
    }

    close(callbackBefore) {
        if (!this.isOpen) return;

        this.isOpen = false;

        if (typeof callbackBefore === 'function') {
            callbackBefore();
        }

        gsap.to(this.modalElement, {
            opacity: 0,
            duration: this.duration,
            ease: 'power2.in',
            onComplete: () => {
                const modalCloseEvent = new CustomEvent('modalClose', {
                    detail: {
                        message: 'Modal has been closed',
                        time: new Date(),
                    },
                });

                this.modalElement.style.display = 'none';
                this.modalElement.setAttribute('aria-hidden', 'true');

                document.documentElement.style.overflow = '';

                if (this.lastFocusedElement) {
                    this.lastFocusedElement.focus();
                }

                document.dispatchEvent(modalCloseEvent);
            },
        });
    }

    trapFocus(e) {
        const focusableElements = this.modalElement.querySelectorAll(
            'button, [href], input, select, textarea, [tabindex]:not([tabindex="-1"])'
        );
        const firstElement = focusableElements[0];
        const lastElement = focusableElements[focusableElements.length - 1];

        if (e.shiftKey) {
            if (document.activeElement === firstElement) {
                lastElement.focus();
                e.preventDefault();
            }
        } else {
            if (document.activeElement === lastElement) {
                firstElement.focus();
                e.preventDefault();
            }
        }
    }

    focusFirstElement(parent = this.modalElement) {
        console.log('parent', parent);

        if (!parent) return;

        const firstFocusableElement = parent.querySelector(this.focusSelectors);

        console.log('firstFocusableElement', firstFocusableElement);

        if (firstFocusableElement) {
            firstFocusableElement.focus();
        }

        this.modalElement.setAttribute('aria-hidden', 'false');
    }
}
