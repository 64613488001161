import Swiper from 'swiper';
import { gsap } from 'gsap';
import { Modal } from '../layouts/modal';
import { Navigation, Pagination } from 'swiper/modules';

class TestimonialsModal {
    constructor() {
        this.testimonialModal = new Modal('.testimonials-modal');
        this.testimonialModalContent = document.querySelector('.testimonials-modal [data-modal-content]');
        this.testimonialModalOpenTriggerSelector = '[data-testimonials-modal-open]'; // Ajout de la définition
        this.testimonialModalCloseTriggerSelector = '[data-testimonials-modal-close]'; // Ajout de la définition
        this.testimonialModalSwiperHasBeenInit = false;
        this.duration = 0.5;
        this.x = '100%';
    }

    init() {
        if (!document.querySelector(this.testimonialModalOpenTriggerSelector)) return false;

        document.addEventListener('click', (e) => {
            const testimonialTriggerOpen = e.target.closest(this.testimonialModalOpenTriggerSelector);
            const testimonialTriggerClose = e.target.closest(this.testimonialModalCloseTriggerSelector);

            if (testimonialTriggerOpen) {
                e.preventDefault();

                this.removeCurrentClassFromAllTriggers();
                testimonialTriggerOpen.classList.add('current');

                this.testimonialModal.open(() => {
                    this.initSwiper();
                    this.testimonialModalSlider.classList.remove('loading');

                    this.showModalContent(() => {
                        this.goToCurrentSlide(
                            testimonialTriggerOpen, 
                            () => {
                                const currentSLide = this.getCurrentSlide();
                                this.testimonialModal.focusFirstElement(currentSLide)
                            }
                        );
                        this.updateCurrentTrigger(this.testimonialModalSwiper.activeIndex);
                        // this.updateThemeColor(this.testimonialModalSwiper.activeIndex);
                        this.updateSwiperWrapperHeight();
                    });
                }, false);
            }

            if (testimonialTriggerClose) {
                e.preventDefault();
                this.hideModalContent();
            }
        });

        document.addEventListener('modalClose', () => {
            this.hideModalContent();
            this.removeCurrentClassFromAllTriggers();
        });

        window.addEventListener('resizeEnd', () => {
            if (!this.testimonialModal.isOpen) return;
            this.updateSwiperWrapperHeight();
            console.log('this.updateSwiperWrapperHeight()');
        })
    }

    initSwiper() {
        this.testimonialModalSlider = this.testimonialModalContent.querySelector('[data-modal-slider]');
        this.swiperParams = {
            modules: [Navigation, Pagination],
            navigation: {
                nextEl: this.testimonialModalSlider.querySelector('.swiper-navigation-next'),
                prevEl: this.testimonialModalSlider.querySelector('.swiper-navigation-prev'),
            },
            pagination: {
                el: this.testimonialModalSlider.querySelector('.swiper-pagination'),
                type: 'bullets',
                clickable: true,
            },
        };

        this.testimonialModalSlider.classList.add('loading');

        if (!this.testimonialModalSwiperHasBeenInit) {
            this.testimonialModalSwiper = new Swiper(this.testimonialModalSlider, this.swiperParams);

            this.testimonialModalSwiper.on('slideChange', () => {
                this.updateCurrentTrigger(this.testimonialModalSwiper.activeIndex);
                // this.updateThemeColor(this.testimonialModalSwiper.activeIndex);
            });

            this.testimonialModalSwiperHasBeenInit = true;
        }
    }

    getCurrentSlide() {
        return this.testimonialModalSwiper.slides[this.testimonialModalSwiper.activeIndex];
    }

    goToCurrentSlide(trigger, callback, speed = 600) {
        const currentIndex = Number(trigger.getAttribute('data-slide-index'));
        const duration = !speed ? 600 : isNaN(speed) ? 600 : speed;

        if (!this.testimonialModalSwiper && isNaN(currentIndex)) return;
        this.testimonialModalSwiper.slideTo(currentIndex, duration);
        setTimeout(callback, duration);
    }

    hideModalContent(callback) {
        if (!this.testimonialModalContent) return;

        gsap.to(this.testimonialModalContent, {
            x: this.x,
            duration: this.duration,
            ease: 'back.in(1.4)',
            onComplete: () => {
                this.testimonialModal.close();
                this.testimonialModalContent.style.opacity = 0;
                this.testimonialModalContent.style.display = 'none';

                if (typeof callback === 'function') {
                    callback();
                }
            },
        });
    }

    removeCurrentClassFromAllTriggers() {
        document.querySelectorAll(this.testimonialModalOpenTriggerSelector).forEach(trigger => {
            trigger.classList.remove('current');
        });
    }

    showModalContent(callback) {
        if (!this.testimonialModalContent) return;

        this.testimonialModalContent.style.display = 'flex';

        gsap.fromTo(this.testimonialModalContent,
            {
                opacity: 0,
                x: this.x,
            },
            {
                duration: this.duration,
                x: 0,
                opacity: 1,
                ease: 'back.out(1.4)',
                onComplete: () => {
                    if (typeof callback === 'function') {
                        callback();
                    }
                },
            });
    }

    updateCurrentTrigger(index) {
        this.removeCurrentClassFromAllTriggers();
        const currentTrigger = document.querySelector(`${this.testimonialModalOpenTriggerSelector}[data-slide-index="${index}"]`);
        if (currentTrigger) {
            currentTrigger.classList.add('current');
        }
    }

    updateThemeColor(index) {
        const currentSlide = this.testimonialModalSwiper.slides[index];

        if (!currentSlide) return;

        const themeColor = currentSlide.getAttribute('data-theme-color');

        this.testimonialModalSlider.setAttribute('style', `--theme-color: ${themeColor}`);
    }

    updateSwiperWrapperHeight() {
        if (!this.testimonialModalSlider) return;

        this.swiperWrapper = this.testimonialModalSlider.querySelector('.swiper-wrapper');
        this.swiperActions = this.testimonialModalSlider.querySelector('.swiper-actions');

        this.swiperWrapper.style.maxHeight = `${this.testimonialModalSlider.offsetHeight - this.swiperActions.offsetHeight}px`;
    }
}


export default {
    init() {
        console.log('init archive-temoignage');
        const testimonialsModal = new TestimonialsModal();
        testimonialsModal.init();
    },
    finalize() {
        // JavaScript to be fired on the home page, after the init JS
    },
    load() {
    },
    complete() {
        // 
    },
};
