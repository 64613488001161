const debounce = (func, delay) => {
    let timer;
    return () => {
        clearTimeout(timer);
        timer = setTimeout(func, delay);
    };
};

const createCustomEvent = (originalEvent, customEventName, delay) => {
    window.addEventListener(originalEvent, debounce(() => {
        window.dispatchEvent(new CustomEvent(customEventName));
    }, delay));
};

export const initScrollEnd = (delay = 200) => {
    createCustomEvent('scroll', 'scrollEnd', delay);
};

export const initResizeEnd = (delay = 250) => {
    createCustomEvent('resize', 'resizeEnd', delay);
};

export const initCustomEvents = ({ scrollDelay, resizeDelay } = {}) => {
    if (scrollDelay !== undefined) initScrollEnd(scrollDelay);
    if (resizeDelay !== undefined) initResizeEnd(resizeDelay);
};