import {MarkerClusterer} from '@googlemaps/markerclusterer';
import SimpleBar from 'simplebar';

/* global google */

var $ = jQuery.noConflict();

class MapGoogle {
    constructor(mapContainer) {
        this.$mapContainer = mapContainer;
        this.$map = this.$mapContainer.find('#gmap');
        this.$mapPlaces = this.$mapContainer.find('#map-places .map-place');
        this.map = null;
        this.bounds = null;
        this.markersArray = [];
        this.markersInBoundsArray = [];
        this.markersInClusterArray = [];
        this.withClusterer = true;
        this.markerClusterer = null;
        self.prevInfowindow = false;

        // Personnalisation du style de l'icône de cluster
        this.clusterStyle = [];
        this.clusterGrid = 30;
        this.iconCluster = this.$map.data('cluster-img');
        this.iconClusterHover = this.$map.data('cluster-img-hover');
        this.iconInactive = this.$map.data('inactive-img');

        // Coordonnées et zoom par défaut
        this.defaultLat = 48.8587017;
        this.defaultLng = 2.2771916;
        this.defaultZoom = 10;
        this.maxZoom = 13;
        this.allowScrollWheel = false;

        this.googleMapConfig = window._googleMapConfig;

        if (typeof this.$map.data('allow-scroll-wheel') !== 'undefined') {
            this.allowScrollWheel = this.$map.data('allow-scroll-wheel');
        }

        if (typeof this.$map[0] !== 'undefined') {
            this.init();
        }
    }

    init() {
        var self = this;
        self.loadMap();

        // Fermeture de la fenêtre info mobile
        $('.mobile-infowindow .js-close').on('click', function (e) {
            e.preventDefault();
            $('.mobile-infowindow').removeClass('active');
        });
    }

    loadMap() {
        var self = this;

        self.bounds = new google.maps.LatLngBounds();

        self.map = new google.maps.Map(self.$map[0], {
            center: {lat: self.defaultLat, lng: self.defaultLng},
            zoom: self.defaultZoom,
            scrollwheel: self.allowScrollWheel,
            mapTypeControl: false,
            streetViewControl: false,
            panControl: false,
            scaleControl: false,
            fullscreenControl: false,
            zoomControl: true,
            zoomControlOptions: {
                position: google.maps.ControlPosition.RIGHT_CENTER,
            },
            styles: self.googleMapConfig,
        });

        // Style unique pour les clusters
        self.clusterStyle = [
            {
                url: self.iconCluster,
                width: 40,
                height: 40,
                textColor: '#FFF',
                anchorIcon: [40, 20], // facultatif
                // textSize: 14,      // facultatif, si vous souhaitez ajuster la taille du texte
            },
        ];

        // Fermer l'infowindow en cliquant sur la map
        google.maps.event.addListener(self.map, 'click', function () {
            if (self.prevInfowindow) {
                self.prevInfowindow.close();
            }
        });

        // Créer les marqueurs
        if (self.havePoints()) {
            self.setPoints();
            if (self.withClusterer) {
                new MarkerClusterer(self.map, self.markersInClusterArray, {
                    styles: self.clusterStyle,
                    gridSize: self.clusterGrid,
                    // AJOUT: la fonction calculator pour afficher le nombre de marqueurs
                    calculator: function (markers) {
                        console.log(markers.length);
                        return {
                            text: String(markers.length), // Nombre de marqueurs
                            index: 1, // On n'a défini qu'un seul style (self.clusterStyle[0])
                        };
                    },
                });
            }
            self.fitBounds();
        } else if (self.withClusterer) {
            // S'il n'y a pas de points, on crée quand même un MarkerClusterer
            self.markerClusterer = new MarkerClusterer(
                self.map,
                self.markersInClusterArray
            );
            self.markerClusterer.setMap(self.map);
        }
    }

    havePoints() {
        return this.$mapPlaces.length > 0;
    }

    fitBounds() {
        if (this.markersInBoundsArray.length > 0) {
            this.map.fitBounds(this.bounds);

            // Si un seul marqueur, on zoome éventuellement au niveau maxZoom
            if (this.markersInBoundsArray.length === 1) {
                var self = this;
                google.maps.event.addListenerOnce(this.map, 'idle', function () {
                    self.map.setZoom(self.maxZoom);
                });
            }
        } else {
            this.map.setCenter(
                new google.maps.LatLng(this.defaultLat, this.defaultLng)
            );
            this.map.setZoom(this.defaultZoom);
        }
    }

    clearBounds() {
        this.bounds = new google.maps.LatLngBounds();
    }

    clearMarkers() {
        for (var i = 0; i < this.markersArray.length; i++) {
            this.markersArray[i].setMap(null);
        }
        this.markersArray = [];
        this.markersInClusterArray = [];
        this.markersInBoundsArray = [];
        if (this.withClusterer && this.markerClusterer) {
            this.markerClusterer.clearMarkers();
        }
    }

    reloadPoints() {
        this.clearMarkers();
        this.clearBounds();
        this.setPoints();
        if (this.withClusterer && this.markerClusterer) {
            this.markerClusterer.addMarkers(this.markersInClusterArray);
            this.markerClusterer.setMap(this.map);
        }
        this.fitBounds();
    }

    setPoints() {
        // Vous pourriez avoir d’autres méthodes pour ajouter différents types de points
        // this.setInactivesPoints();
        this.setPlacesPoints();
    }

    setPlacesPoints() {
        var self = this;
        self.$mapPlaces = self.$mapContainer.find('#map-places .map-place');
        self.$mapPlaces.each(function () {
            var elt = $(this);
            var coord = [elt.data('coord-lat'), elt.data('coord-lng')];
            var inBounds = elt.data('in-bounds');
            var inCluster = elt.data('in-cluster');

            if (coord.length === 2) {
                var lat = parseFloat(coord[0]);
                var lng = parseFloat(coord[1]);

                var icon = {
                    url: elt.data('map-icon'), // chemin de l'icône
                    scaledSize: new google.maps.Size(30, 30),
                    origin: new google.maps.Point(0, 0),
                    anchor: new google.maps.Point(0, 0),
                };

                var text = elt.data('title');
                if (lat !== 0 && lng !== 0) {
                    var latLng = {lat: lat, lng: lng};
                    var marker = new google.maps.Marker({
                        icon: icon,
                        map: self.map,
                        zIndex: 99999,
                        position: latLng,
                        title: text,
                    });

                    marker.addListener('click', function () {
                        console.log('marker clicked');
                        if (self.prevInfowindow) {
                            self.prevInfowindow.close();
                        }

                        var infowindow = null;

                        if (parseInt($(window).width()) >= 550) {
                            infowindow = new google.maps.InfoWindow({
                                content:
                                    '<div class="map-info item" data-marker="' +
                                    elt.data('marker') +
                                    '">' +
                                    elt.html() +
                                    '</div>',
                            });
                            infowindow.open(self.map, marker);
                            self.prevInfowindow = infowindow;

                            google.maps.event.addListener(
                                infowindow,
                                'domready',
                                function () {
                                    $('.gm-style-iw').addClass(
                                        'gm-style-iw-container'
                                    );
                                    // S'il y a un carrousel "multiple"
                                    if (elt.data('multiple') === true) {
                                        self.initSlick();
                                    }
                                }
                            );
                        } else {
                            // Affichage info sous forme "mobile"
                            $('.mobile-infowindow .content').html(
                                '<div class="map-info item" data-marker="' +
                                elt.data('marker') +
                                '">' +
                                elt.html() +
                                '</div>'
                            );
                            $('.mobile-infowindow').addClass('active');
                            self.initSlick();
                        }
                    });

                    self.markersArray.push(marker);

                    // On l’ajoute au cluster si c'est autorisé
                    if (typeof inCluster === 'undefined' || inCluster !== false) {
                        self.markersInClusterArray.push(marker);
                    }

                    var id = self.markersArray.length - 1;
                    elt.attr('data-marker', id);
                    elt.attr('id', 'place-' + id);

                    // On l’étend aux bounds si c'est autorisé
                    if (typeof inBounds === 'undefined' || inBounds !== false) {
                        self.bounds.extend(marker.position);
                        self.markersInBoundsArray.push(marker);
                    }
                }
            }
        });

        jQuery(window).trigger('app.component.gmap.place-loaded');
    }

    resetInfoWindow() {
        if (parseInt($(window).width()) >= 550) {
            if (this.prevInfowindow) {
                this.prevInfowindow.close();
            }
        } else {
            $('.mobile-infowindow').removeClass('active');
        }
    }

    findMarkerCluster(marker) {
        var self = this;
        var r = false;
        if (!self.markerClusterer) return r;
        var clusters = self.markerClusterer.getClusters();
        $.each(clusters, function (i, c) {
            var ms = c.getMarkers();
            if (ms.length > 1) {
                $.each(ms, function (j, m) {
                    if (marker === m) {
                        r = c;
                    }
                });
            }
        });
        return r;
    }

    initSlick() {
        console.log('initSlick');
        $('.map-info .slick-map:not(.slick-initialized)').slick({
            slidesToShow: 1,
            infinite: false,
            fade: false,
            arrows: false,
            dots: true,
            mobileFirst: true,
            speed: 600,
            autoplay: false,
        });

        const slidesContainer = document.querySelectorAll('.map-info .slick-initialized .place-container');
        if (!slidesContainer.length) return;

        slidesContainer.forEach(container => new SimpleBar(container));
    }
}

export default {
    init() {
        if (!document.querySelector('.map-container')) return;
        $('.map-container').each(function () {
            new MapGoogle($(this));
        });
    },
};
