import Swiper from 'swiper';
import { gsap } from 'gsap';
import { Modal } from '../layouts/modal';
import { Navigation, Pagination } from 'swiper/modules';

class Poles {
    constructor() {
        this.poleModal = new Modal('.poles-modal');
        this.poleModalContent = document.querySelector('.poles-modal [data-modal-content]');
        this.poleModalOpenTriggerlist = document.querySelector('ul[data-parent-triggers]');
        this.poleModalOpenTriggerItems = document.querySelectorAll('ul[data-parent-triggers] li');
        this.poleModalOpenTriggerSelector = '[data-pole-modal-open]';
        this.poleModalCloseTriggerSelector = '[data-poles-modal-close]';
        this.poleModalSwiperHasBeenInit = false;
        this.duration = 0.5;
        this.x = '100%';
        this.isMousemoveListenerAdded = false;
    }

    init() {
        if (!document.querySelector(this.poleModalOpenTriggerSelector)) return false;

        // this.initMousemoveAnimation();

        document.addEventListener('click', (e) => {
            const poleTriggerOpen = e.target.closest(this.poleModalOpenTriggerSelector);
            const poleTriggerClose = e.target.closest(this.poleModalCloseTriggerSelector);

            if (poleTriggerOpen) {
                e.preventDefault();

                this.removeCurrentClassFromAllTriggers();
                poleTriggerOpen.classList.add('current');

                this.poleModal.open(() => {
                    this.initSwiper();
                    this.poleModalSlider.classList.remove('loading');

                    this.showModalContent(() => {
                        this.goToCurrentSlide(
                            poleTriggerOpen,
                            () => {
                                const currentSLide = this.getCurrentSlide();
                                this.poleModal.focusFirstElement(currentSLide)
                            }
                        );
                        this.updateCurrentTrigger(this.poleModalSwiper.activeIndex);
                        this.updateThemeColor(this.poleModalSwiper.activeIndex);
                        this.updateSwiperWrapperHeight();
                    });
                }, false);
            }

            if (poleTriggerClose) {
                e.preventDefault();
                this.hideModalContent();
            }
        });

        document.addEventListener('modalClose', () => {
            this.hideModalContent();
            this.removeCurrentClassFromAllTriggers();
        });

        window.addEventListener('resizeEnd', () => {
            if (!this.poleModal.isOpen) return;
            this.updateSwiperWrapperHeight();
            console.log('this.updateSwiperWrapperHeight()');
        })
    }

    initSwiper() {
        this.poleModalSlider = this.poleModalContent.querySelector('[data-modal-slider]');
        this.swiperParams = {
            modules: [Navigation, Pagination],
            navigation: {
                nextEl: this.poleModalSlider.querySelector('.swiper-navigation-next'),
                prevEl: this.poleModalSlider.querySelector('.swiper-navigation-prev'),
            },
            pagination: {
                el: this.poleModalSlider.querySelector('.swiper-pagination'),
                type: 'bullets',
                clickable: true,
            },
        };

        this.poleModalSlider.classList.add('loading');

        if (!this.poleModalSwiperHasBeenInit) {
            this.poleModalSwiper = new Swiper(this.poleModalSlider, this.swiperParams);

            this.poleModalSwiper.on('slideChange', () => {
                this.updateCurrentTrigger(this.poleModalSwiper.activeIndex);
                this.updateThemeColor(this.poleModalSwiper.activeIndex);
            });

            this.poleModalSwiperHasBeenInit = true;
        }
    }

    getCurrentSlide() {
        return this.poleModalSwiper.slides[this.poleModalSwiper.activeIndex];
    }

    goToCurrentSlide(trigger, callback, speed = 600) {
        const currentIndex = Number(trigger.getAttribute('data-slide-index'));
        const duration = !speed ? 600 : isNaN(speed) ? 600 : speed;

        if (!this.poleModalSwiper && isNaN(currentIndex)) return;
        this.poleModalSwiper.slideTo(currentIndex, duration);
        setTimeout(callback, duration);
    }

    hideModalContent(callback) {
        if (!this.poleModalContent) return;

        gsap.to(this.poleModalContent, {
            x: this.x,
            duration: this.duration,
            ease: 'back.in(1.4)',
            onComplete: () => {
                this.poleModal.close();
                this.poleModalContent.style.opacity = 0;
                this.poleModalContent.style.display = 'none';

                if (typeof callback === 'function') {
                    callback();
                }
            },
        });
    }

    removeCurrentClassFromAllTriggers() {
        document.querySelectorAll(this.poleModalOpenTriggerSelector).forEach(trigger => {
            trigger.classList.remove('current');
        });
    }

    showModalContent(callback) {
        if (!this.poleModalContent) return;

        this.poleModalContent.style.display = 'flex';

        gsap.fromTo(this.poleModalContent,
            {
                opacity: 0,
                x: this.x,
            },
            {
                duration: this.duration,
                x: 0,
                opacity: 1,
                ease: 'back.out(1.4)',
                onComplete: () => {
                    if (typeof callback === 'function') {
                        callback();
                    }
                },
            });
    }

    updateCurrentTrigger(index) {
        this.removeCurrentClassFromAllTriggers();
        const currentTrigger = document.querySelector(`${this.poleModalOpenTriggerSelector}[data-slide-index="${index}"]`);
        if (currentTrigger) {
            currentTrigger.classList.add('current');
        }
    }

    updateThemeColor(index) {
        const currentSlide = this.poleModalSwiper.slides[index];

        if (!currentSlide) return;

        const themeColor = currentSlide.getAttribute('data-theme-color');

        this.poleModalSlider.setAttribute('style', `--theme-color: ${themeColor}`);
    }

    updateSwiperWrapperHeight() {
        if (!this.poleModalSlider) return;

        this.swiperWrapper = this.poleModalSlider.querySelector('.swiper-wrapper');
        this.swiperActions = this.poleModalSlider.querySelector('.swiper-actions');

        this.swiperWrapper.style.maxHeight = `${this.poleModalSlider.offsetHeight - this.swiperActions.offsetHeight}px`;
    }

    handleMousemove(e) {
        // Désactiver l'animation pour les petites résolutions
        if (window.innerWidth < 960) return;

        const rect = this.poleModalOpenTriggerlist.getBoundingClientRect();
        const x = e.clientX - rect.left;
        const y = e.clientY - rect.top;

        // Calculer le déplacement avec une légère réduction pour un mouvement fluide
        const moveX = (x - rect.width / 2) * 0.1;
        const moveY = (y - rect.height / 2) * 0.1;

        // Utiliser GSAP pour animer les éléments <li>
        gsap.to(this.poleModalOpenTriggerItems, {
            x: moveX,
            y: moveY,
            duration: 0, // Durée de l'animation
            ease: 'linear', // Type d'interpolation pour un mouvement fluide
            stagger: 0.05, // Appliquer un léger délai à chaque item pour créer un effet en cascade
        });
    }

    addMousemoveListener() {
        // Vérifier si l'écouteur n'est pas déjà ajouté
        if (!this.isMousemoveListenerAdded) {
            this.poleModalOpenTriggerlist.addEventListener('mousemove', this.handleMousemove.bind(this));
            this.isMousemoveListenerAdded = true;
        }
    }

    removeMousemoveListener() {
        // Retirer l'écouteur si déjà présent
        if (this.isMousemoveListenerAdded) {
            this.poleModalOpenTriggerlist.removeEventListener('mousemove', this.handleMousemove.bind(this));

            // Remettre les éléments à leur position initiale en réinitialisant la transformation
            gsap.to(this.poleModalOpenTriggerItems, {
                x: 0,
                y: 0,
                duration: 0.3,
                ease: 'linear',
            });

            this.isMousemoveListenerAdded = false;
        }
    }

    checkWindowSize() {
        // Ajouter ou retirer l'écouteur en fonction de la taille de la fenêtre
        if (window.innerWidth >= 960) {
            this.addMousemoveListener();
        } else {
            this.removeMousemoveListener();
        }
    }

    initMousemoveAnimation() {
        // Gérer les changements de taille de la fenêtre
        window.addEventListener('resize', this.checkWindowSize.bind(this));
        this.checkWindowSize(); // Vérifier la taille au chargement initial
    }

}

export default new Poles();